import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'appShortName',
  standalone: false,
})
export class AppShortNamePipe implements PipeTransform {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  transform(value: string, _args?: any): any {
    if (!value) {
      return value;
    }
    const keywords = [' ', '-', 'Pal', 'Pac'];
    let found = false;
    let shortName = '';
    for (let i = 0; i < keywords.length; i++) {
      if (value.toLowerCase().indexOf(keywords[i].toLowerCase()) !== -1) {
        if (keywords[i].length >= value.length) {
          shortName = value;
        } else {
          shortName =
            value.substring(0, 1) +
            value[
              value.toLowerCase().indexOf(keywords[i].toLowerCase()) +
                keywords[i].length
            ].toLowerCase();
        }
        found = true;
        break;
      }
    }

    if (!found) {
      shortName = value.substring(0, 2);
    }

    return shortName;
  }
}
