import { Directive, HostListener, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[uiKitPreventCopy]',
  standalone: false,
})
export class PreventCopyDirective {
  @Output() triedToCopy = new EventEmitter();
  @HostListener('copy', ['$event']) blockCopy(e: KeyboardEvent) {
    e.preventDefault();
    this.triedToCopy.emit();
  }
  @HostListener('cut', ['$event']) blockCut(e: KeyboardEvent) {
    e.preventDefault();
    this.triedToCopy.emit();
  }
}
