import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  Inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FlexModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { PdLetDirectiveModule } from '@cdk/pd-let';
import { DsSpacingModule } from '@design-system/cdk/spacing';

import { UserService } from '@features/auth';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FreshdeskWidgetService } from '@paldesk/shared-lib/features/freshdesk-widget';
import { SurveyDialogService } from '@paldesk/survey';
import { GoogleAnalytics4DirectiveModule } from '@shared-lib/google-analytics';
import { AppWrapperService } from '../app-wrapper.service';
import { gAAppWrapperV2Constants } from '../shared/model';
import { DS_APP_WRAPPER_CONFIG, DsAppWrapperConfig } from '../config';

@Component({
  selector: 'ds-new-paldesk-help-feedback-menu',
  imports: [
    CommonModule,
    MatCardModule,
    FlexModule,
    MatIconModule,
    MatButtonModule,
    TranslateModule,
    DsSpacingModule,
    GoogleAnalytics4DirectiveModule,
    PdLetDirectiveModule,
  ],
  templateUrl: './help-feedback-menu.component.html',
  styleUrls: ['./help-feedback-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HelpFeedbackMenuComponent {
  userLanguage = 'en';
  labels = [];

  public googleAnalyticsConstants = gAAppWrapperV2Constants;

  constructor(
    private freshdeskService: FreshdeskWidgetService,
    public userService: UserService,
    @Inject(DS_APP_WRAPPER_CONFIG) public config: DsAppWrapperConfig,
    private translateService: TranslateService,
    private destroyRef: DestroyRef,
    public surveyService: SurveyDialogService,
    private service: AppWrapperService,
    private dialog: MatDialog,
  ) {
    this.userService.currentUser
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((user) => {
        if (user) {
          this.userLanguage = user.lang ? user.lang.toLowerCase() : 'en';
        }
      });
  }

  navigateTo(pageURL: string | null | undefined) {
    if (pageURL) {
      this.service.closeOverlay();
      window.open(pageURL, '_self');
    }
  }

  navigateToNewTab(pageURL: string | null | undefined) {
    if (pageURL) {
      this.service.closeOverlay();
      window.open(pageURL, '_blank');
    }
  }

  openFreshdeskWidget(widgetType: 'help' | 'idea') {
    if (widgetType === 'help') {
      if (this.userService.isAuthorized) {
        this.freshdeskService.openTheWidget();
      } else {
        window.location.href = 'mailto:paldesk@palfinger.com';
      }
    } else {
      this.openIdeaWidget(this.userLanguage);
    }
  }

  openIdeaWidget(lang: string) {
    this.translateService
      .get([
        'freshdesk_widget.banner_idea',
        'freshdesk_widget.title_idea',
        'freshdesk_widget.submit',
        'freshdesk_widget.confirmation',
        'freshdesk_widget.launcher',
      ])
      .subscribe((data) => {
        this.labels[lang] = {
          banner: data['freshdesk_widget.banner_idea'],
          launcher: data['freshdesk_widget.launcher'],
          contactForm: {
            title: data['freshdesk_widget.title_idea'],
            submit: data['freshdesk_widget.submit'],
            confirmation: data['freshdesk_widget.confirmation'],
          },
        };
        this.freshdeskService.openTheWidget(this.labels);
      });
  }

  openFeedbackDialog() {
    this.surveyService.openQualtricsSurvey(
      SurveyDialogService.paldeskCSISurveyId,
    );
  }
}
