import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { Subject, combineLatest } from 'rxjs';
import {
  debounceTime,
  filter,
  map,
  pairwise,
  startWith,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { FilterValue } from './model/filter-value.model';
import { DsFilterPaginationValue } from './model/pagination-value.model';

type SelectedDataTypes = string | boolean | number | null;
@Component({
  selector: 'ds-filter-input',
  templateUrl: './filter-input.component.html',
  styleUrls: ['./filter-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class DsFilterInputComponent implements AfterViewInit, OnDestroy {
  @ViewChild('checkBox') checkBox: any;
  @ViewChild('inputSelect') inputSelect: any;
  @ViewChild(MatSelect, { static: false }) select: MatSelect;
  @ViewChild('scrollPanel', { read: ElementRef }) set scrollPanel(
    value: ElementRef,
  ) {
    if (value) {
      value.nativeElement.addEventListener('scroll', (event: any) => {
        if (
          !this.isDataLoading &&
          this.data.length < this.totalCount &&
          // scroll up to 80px to the bottom (about 2 options still left)
          event.target &&
          event.target.scrollHeight - (event.target.scrollTop + 80) <=
            event.target.clientHeight
        ) {
          this.expandDisplayedData();
        }
      });
    }
  }

  @Input() set data(value: FilterValue[]) {
    if (value) {
      this._data = value;
      value.forEach((x) => (this.dataLookup['' + x.value] = x));
      this.isDataLoading = false;
    }
  }
  get data() {
    return this._data;
  }

  @Input() control: UntypedFormControl;
  @Input() errorStateMatcher: ErrorStateMatcher;
  @Input() label: string;
  @Input() totalCount: number;
  @Input() isSelectAllDisabled: boolean;
  @Input() isSingleSelectionDisabled: boolean;
  @Input() isMultiChoiceDisabled: boolean;
  @Input() isPaginated: boolean;
  @Input() showNotAvailable = true;
  @Input() hideRequiredMarker = false;
  @Input() isLoading = false;

  @Output()
  pagination: EventEmitter<DsFilterPaginationValue> = new EventEmitter();
  isDataLoading = true;
  searchControl = new UntypedFormControl();
  dataLookup: { [value: string]: FilterValue } = {};

  private _destroy$ = new Subject<void>();
  private _pageIndex$: Subject<number> = new Subject();
  private _data: FilterValue[];
  private _pageIndex = 0;

  ngAfterViewInit() {
    if (this.isPaginated) this.applyPagination();
  }

  get selectedButNotLoadedValues(): (string | boolean | number)[] {
    const value: (string | boolean | number)[] | string | boolean | number =
      this.control.value;
    if (this.isArray(value)) {
      return (value as (string | boolean | number)[]).filter(
        (x) => !this._data.some((y) => y.value === x),
      );
    } else if (value && !this._data.some((y) => y.value === value)) {
      return [value as string | boolean | number];
    }
    return [];
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

  toggleAllSelection() {
    const isSelected = this.control.value?.length > 0;
    const isAllSelected = this.data.length === this.control.value?.length;
    if ((isSelected && !isAllSelected) || (!isAllSelected && !isSelected)) {
      this.control.patchValue(this.data.map((item) => item.value));
    } else {
      this.control.patchValue([]);
    }
  }

  clear(event: Event) {
    event.stopPropagation();
    this.control.reset();
  }

  sortEntries(
    data: FilterValue[] | null,
    selectedData: SelectedDataTypes | Array<SelectedDataTypes>,
  ): FilterValue[] {
    let retVal: FilterValue[] = [];
    if (data && selectedData) {
      data.sort((a, b) => (a.value > b.value ? 1 : -1)); //sort all the data for case, they come unsorted, and then perform advanced sorting
      if (this.isArray(selectedData)) {
        retVal = [
          ...this._sortArrayInArray(data, selectedData as SelectedDataTypes[]),
        ];
      } else {
        retVal = this._sortValueInArray(
          data,
          selectedData as SelectedDataTypes,
        );
      }
    } else if (data) {
      retVal = data;
    }
    return retVal;
  }

  applyPagination() {
    combineLatest([
      this.searchControl.valueChanges.pipe(
        startWith(''),
        debounceTime(200),
        map((searchVal: string) => (searchVal ? searchVal.trim() : '')),
        //reset pagination (page = 1) if we want to search for entries
        tap(() => {
          //NOTE: this will emit twice in the observer since the search has changed and also the page has changed
          //to avoid unnecessary emitting of values we filter this use case in the pipe bellow
          this._pageIndex$.next(0);
          this._pageIndex = 0;
        }),
      ),
      this._pageIndex$,
    ])
      .pipe(
        startWith(['', 0] as [string, number]),
        pairwise(),
        filter(
          ([[prevSearch, prevPageIndex], [currSearch, currPageIndex]]: [
            [string, number],
            [string, number],
          ]) =>
            //If previous page is lager then current page this meant we have entered something in search
            //since the search will set the page to 1 we need to filter out a "middle" state
            //where search isn't applied but just the page
            !(
              (prevSearch === currSearch && prevPageIndex > currPageIndex) ||
              //Filter out if nothing has changed
              (prevSearch === currSearch && prevPageIndex === currPageIndex)
            ),
        ),
        map(([, curr]) => curr),
        takeUntil(this._destroy$),
      )
      .subscribe(([search, page]: [string, number]) => {
        this.pagination.emit({
          pageIndex: page,
          searchValue: search,
        } as DsFilterPaginationValue);
        this.isDataLoading = true;
      });
  }

  expandDisplayedData() {
    this._pageIndex$.next(++this._pageIndex);
  }

  isArray(data: any) {
    return Array.isArray(data);
  }

  focusOnChange() {
    if (this.inputSelect) {
      this.inputSelect.nativeElement.focus();
    }
  }

  private _sortValueInArray(
    data: FilterValue[],
    selectedData: SelectedDataTypes,
  ): FilterValue[] {
    return [...data].sort((a, b) =>
      !selectedData === a.value && selectedData === b.value
        ? 1
        : selectedData === a.value
          ? -1
          : 0,
    );
  }

  private _sortArrayInArray(
    data: FilterValue[],
    selectedData: Array<SelectedDataTypes>,
  ): FilterValue[] {
    return [...data].sort((a, b) => {
      if (
        !selectedData.some((x) => x === a.value) &&
        selectedData.some((x) => x === b.value)
      ) {
        return 1; //sort/move selected items at the beginning of data
      } else if (
        selectedData.some((x) => x === a.value) &&
        selectedData.some((x) => x === b.value)
      ) {
        return 1; //sort selected items between each other and keep them in first places in filteredData
      } else if (selectedData.some((x) => x === a.value)) {
        return -1;
      } else {
        return 0; //do not sort the rest of filteredData
      }
    });
  }
}
