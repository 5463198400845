import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { ConfirmEmailComponent } from './confirm-email.component';
import { PreventCopyModule } from '../prevent-copy/prevent-copy.module';
import { FlexModule } from '@angular/flex-layout';

/**
 * #129664
 * Note: Needs to be discussed if still needed, could be moved to BPM
 * Consumd by: BPM
 */
@NgModule({
  declarations: [ConfirmEmailComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    TranslateModule,
    PreventCopyModule,
    FlexModule,
  ],
  exports: [ConfirmEmailComponent],
})
export class ConfirmEmailModule {}
