import { Pipe, PipeTransform } from '@angular/core';
import { SurveyDialogData } from '../survey-dialog/survey-dialog.component';

@Pipe({
  name: 'surveyUrl',
  standalone: false,
})
export class SurveyPipe implements PipeTransform {
  transform(value: SurveyDialogData): string {
    if (value?.additionalQueryParams && value?.additionalQueryParams?.size) {
      return '';
    }

    return value?.surveyUrl;
  }
}
