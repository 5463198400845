/**
 * v3 - Business Partner Management
 * Business Partner Management API
 *
 * The version of the OpenAPI document: 3.0
 * Contact: m.kollar@palfinger.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProductLineAreaResponse } from './productLineAreaResponse';


export interface PalsoftApplicationResponse { 
    
    application_id: string;
    
    name: string;
    
    costs: boolean;
    
    application_product_areas: Array<ProductLineAreaResponse>;
    
    licence_types: Array<PalsoftApplicationResponseLicenceTypes>;
}
export enum PalsoftApplicationResponseLicenceTypes {
    Server = 'Server',
    Client = 'Client',
    Single = 'Single',
    Network = 'Network'
};



