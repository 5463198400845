/**
 * v3 - Business Partner Management
 * Business Partner Management API
 *
 * The version of the OpenAPI document: 3.0
 * Contact: m.kollar@palfinger.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { CreateCmiLicenceRequest } from '../model/createCmiLicenceRequest';
// @ts-ignore
import { CreateCmiLicenceResponse } from '../model/createCmiLicenceResponse';
// @ts-ignore
import { CreateLicenceRequest } from '../model/createLicenceRequest';
// @ts-ignore
import { CreateLicenceResponse } from '../model/createLicenceResponse';
// @ts-ignore
import { GetApplicationsResponse } from '../model/getApplicationsResponse';
// @ts-ignore
import { GetCmiLicencesResponse } from '../model/getCmiLicencesResponse';
// @ts-ignore
import { GetLicenceResponse } from '../model/getLicenceResponse';
// @ts-ignore
import { GetLicencesResponseDtoPaged } from '../model/getLicencesResponseDtoPaged';
// @ts-ignore
import { PalsoftLicenceConfigurationResponse } from '../model/palsoftLicenceConfigurationResponse';
// @ts-ignore
import { ProblemDetails } from '../model/problemDetails';
// @ts-ignore
import { RegenerateLicenceRequest } from '../model/regenerateLicenceRequest';
// @ts-ignore
import { RegenerateLicenceResponse } from '../model/regenerateLicenceResponse';
// @ts-ignore
import { UpdateLicenceRequest } from '../model/updateLicenceRequest';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class PalsoftLicenceService {

    protected basePath = 'provide_injection_token_for_PalsoftLicenceService';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Generate a CMI licence
     * @param apiVersion The requested API version
     * @param createCmiLicenceRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public bpmCreateCmiLicence(apiVersion: '3.0', createCmiLicenceRequest?: CreateCmiLicenceRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'application/problem+json',}): Observable<CreateCmiLicenceResponse>;
    public bpmCreateCmiLicence(apiVersion: '3.0', createCmiLicenceRequest?: CreateCmiLicenceRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'application/problem+json',}): Observable<HttpResponse<CreateCmiLicenceResponse>>;
    public bpmCreateCmiLicence(apiVersion: '3.0', createCmiLicenceRequest?: CreateCmiLicenceRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'application/problem+json',}): Observable<HttpEvent<CreateCmiLicenceResponse>>;
    public bpmCreateCmiLicence(apiVersion: '3.0', createCmiLicenceRequest?: CreateCmiLicenceRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'application/problem+json',}): Observable<any> {
        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling bpmCreateCmiLicence.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (apiVersion !== undefined && apiVersion !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>apiVersion, 'api-version');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (OAuth2SecurityScheme) required
        localVarCredential = this.configuration.lookupCredential('OAuth2SecurityScheme');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json',
                'application/problem+json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.post<CreateCmiLicenceResponse>(`${this.configuration.basePath}/PalsoftLicence/CMI`,
            createCmiLicenceRequest,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param apiVersion The requested API version
     * @param createLicenceRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public bpmCreateLicence(apiVersion: '3.0', createLicenceRequest?: CreateLicenceRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'application/problem+json',}): Observable<CreateLicenceResponse>;
    public bpmCreateLicence(apiVersion: '3.0', createLicenceRequest?: CreateLicenceRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'application/problem+json',}): Observable<HttpResponse<CreateLicenceResponse>>;
    public bpmCreateLicence(apiVersion: '3.0', createLicenceRequest?: CreateLicenceRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'application/problem+json',}): Observable<HttpEvent<CreateLicenceResponse>>;
    public bpmCreateLicence(apiVersion: '3.0', createLicenceRequest?: CreateLicenceRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'application/problem+json',}): Observable<any> {
        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling bpmCreateLicence.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (apiVersion !== undefined && apiVersion !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>apiVersion, 'api-version');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (OAuth2SecurityScheme) required
        localVarCredential = this.configuration.lookupCredential('OAuth2SecurityScheme');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json',
                'application/problem+json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.post<CreateLicenceResponse>(`${this.configuration.basePath}/PalsoftLicence/CreateLicence`,
            createLicenceRequest,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get licences for the current user\&#39;s company
     * @param apiVersion The requested API version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public bpmGetCmiLicences(apiVersion: '3.0', observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'application/problem+json',}): Observable<GetCmiLicencesResponse>;
    public bpmGetCmiLicences(apiVersion: '3.0', observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'application/problem+json',}): Observable<HttpResponse<GetCmiLicencesResponse>>;
    public bpmGetCmiLicences(apiVersion: '3.0', observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'application/problem+json',}): Observable<HttpEvent<GetCmiLicencesResponse>>;
    public bpmGetCmiLicences(apiVersion: '3.0', observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'application/problem+json',}): Observable<any> {
        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling bpmGetCmiLicences.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (apiVersion !== undefined && apiVersion !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>apiVersion, 'api-version');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (OAuth2SecurityScheme) required
        localVarCredential = this.configuration.lookupCredential('OAuth2SecurityScheme');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json',
                'application/problem+json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.get<GetCmiLicencesResponse>(`${this.configuration.basePath}/PalsoftLicence/CMI`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the details for the Palsoft Licence
     * @param licenceId Licence ID
     * @param apiVersion The requested API version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public bpmGetLicence(licenceId: number, apiVersion: '3.0', observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'application/problem+json',}): Observable<GetLicenceResponse>;
    public bpmGetLicence(licenceId: number, apiVersion: '3.0', observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'application/problem+json',}): Observable<HttpResponse<GetLicenceResponse>>;
    public bpmGetLicence(licenceId: number, apiVersion: '3.0', observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'application/problem+json',}): Observable<HttpEvent<GetLicenceResponse>>;
    public bpmGetLicence(licenceId: number, apiVersion: '3.0', observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'application/problem+json',}): Observable<any> {
        if (licenceId === null || licenceId === undefined) {
            throw new Error('Required parameter licenceId was null or undefined when calling bpmGetLicence.');
        }
        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling bpmGetLicence.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (apiVersion !== undefined && apiVersion !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>apiVersion, 'api-version');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (OAuth2SecurityScheme) required
        localVarCredential = this.configuration.lookupCredential('OAuth2SecurityScheme');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json',
                'application/problem+json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.get<GetLicenceResponse>(`${this.configuration.basePath}/PalsoftLicence/${encodeURIComponent(String(licenceId))}`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns list of Palsoft Applications with translated application name
     * @param language Two letter iso language code. EN, DE, ...   For correct caching purposes this is not taken from user token
     * @param apiVersion The requested API version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public bpmGetLicenceApplications(language: string, apiVersion: '3.0', observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'application/problem+json',}): Observable<GetApplicationsResponse>;
    public bpmGetLicenceApplications(language: string, apiVersion: '3.0', observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'application/problem+json',}): Observable<HttpResponse<GetApplicationsResponse>>;
    public bpmGetLicenceApplications(language: string, apiVersion: '3.0', observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'application/problem+json',}): Observable<HttpEvent<GetApplicationsResponse>>;
    public bpmGetLicenceApplications(language: string, apiVersion: '3.0', observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'application/problem+json',}): Observable<any> {
        if (language === null || language === undefined) {
            throw new Error('Required parameter language was null or undefined when calling bpmGetLicenceApplications.');
        }
        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling bpmGetLicenceApplications.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (apiVersion !== undefined && apiVersion !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>apiVersion, 'api-version');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json',
                'application/problem+json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.get<GetApplicationsResponse>(`${this.configuration.basePath}/PalsoftLicence/applications/${encodeURIComponent(String(language))}`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param companyId 
     * @param apiVersion The requested API version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public bpmGetLicenceConfiguration(companyId: number, apiVersion: '3.0', observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'application/problem+json',}): Observable<PalsoftLicenceConfigurationResponse>;
    public bpmGetLicenceConfiguration(companyId: number, apiVersion: '3.0', observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'application/problem+json',}): Observable<HttpResponse<PalsoftLicenceConfigurationResponse>>;
    public bpmGetLicenceConfiguration(companyId: number, apiVersion: '3.0', observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'application/problem+json',}): Observable<HttpEvent<PalsoftLicenceConfigurationResponse>>;
    public bpmGetLicenceConfiguration(companyId: number, apiVersion: '3.0', observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'application/problem+json',}): Observable<any> {
        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling bpmGetLicenceConfiguration.');
        }
        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling bpmGetLicenceConfiguration.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (apiVersion !== undefined && apiVersion !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>apiVersion, 'api-version');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (OAuth2SecurityScheme) required
        localVarCredential = this.configuration.lookupCredential('OAuth2SecurityScheme');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json',
                'application/problem+json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.get<PalsoftLicenceConfigurationResponse>(`${this.configuration.basePath}/PalsoftLicence/GetLicenceConfiguration/${encodeURIComponent(String(companyId))}`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param pageNumber Page number (zero based index)
     * @param pageSize Number of records per page
     * @param apiVersion The requested API version
     * @param companyName CompanyName filter  Check if Company.Name or Company.Name2 contains value is performed  If value starts with #, then check if Company.SapId equals value is performed
     * @param bpmCompanyId Company BpmId filter
     * @param userNameOrLogin User first name or last name or login name multi search
     * @param comment Licence comment filter
     * @param licenceCode Licence RequestCode/Identification code  or  Licence AuthorizationCode or  Licence CompanyCode/Debitor code multi search
     * @param isActive Active licences filter
     * @param hasCosts Licneces with costs filter
     * @param applicationId Licence application filter
     * @param sortColumn Name of the column for sorting
     * @param sortDirectionAscending Sort in ascending order when value is true. Sort in descending order otherwise
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public bpmGetLicences(pageNumber: number, pageSize: number, apiVersion: '3.0', companyName?: string, bpmCompanyId?: number, userNameOrLogin?: string, comment?: string, licenceCode?: string, isActive?: boolean, hasCosts?: boolean, applicationId?: string, sortColumn?: 'Company' | 'User' | 'Application' | 'CreatedOn' | 'ModifiedOn', sortDirectionAscending?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'application/problem+json',}): Observable<GetLicencesResponseDtoPaged>;
    public bpmGetLicences(pageNumber: number, pageSize: number, apiVersion: '3.0', companyName?: string, bpmCompanyId?: number, userNameOrLogin?: string, comment?: string, licenceCode?: string, isActive?: boolean, hasCosts?: boolean, applicationId?: string, sortColumn?: 'Company' | 'User' | 'Application' | 'CreatedOn' | 'ModifiedOn', sortDirectionAscending?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'application/problem+json',}): Observable<HttpResponse<GetLicencesResponseDtoPaged>>;
    public bpmGetLicences(pageNumber: number, pageSize: number, apiVersion: '3.0', companyName?: string, bpmCompanyId?: number, userNameOrLogin?: string, comment?: string, licenceCode?: string, isActive?: boolean, hasCosts?: boolean, applicationId?: string, sortColumn?: 'Company' | 'User' | 'Application' | 'CreatedOn' | 'ModifiedOn', sortDirectionAscending?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'application/problem+json',}): Observable<HttpEvent<GetLicencesResponseDtoPaged>>;
    public bpmGetLicences(pageNumber: number, pageSize: number, apiVersion: '3.0', companyName?: string, bpmCompanyId?: number, userNameOrLogin?: string, comment?: string, licenceCode?: string, isActive?: boolean, hasCosts?: boolean, applicationId?: string, sortColumn?: 'Company' | 'User' | 'Application' | 'CreatedOn' | 'ModifiedOn', sortDirectionAscending?: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'application/problem+json',}): Observable<any> {
        if (pageNumber === null || pageNumber === undefined) {
            throw new Error('Required parameter pageNumber was null or undefined when calling bpmGetLicences.');
        }
        if (pageSize === null || pageSize === undefined) {
            throw new Error('Required parameter pageSize was null or undefined when calling bpmGetLicences.');
        }
        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling bpmGetLicences.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (companyName !== undefined && companyName !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>companyName, 'company_name');
        }
        if (bpmCompanyId !== undefined && bpmCompanyId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>bpmCompanyId, 'bpm_company_id');
        }
        if (userNameOrLogin !== undefined && userNameOrLogin !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>userNameOrLogin, 'user_name_or_login');
        }
        if (comment !== undefined && comment !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>comment, 'comment');
        }
        if (licenceCode !== undefined && licenceCode !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>licenceCode, 'licence_code');
        }
        if (isActive !== undefined && isActive !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>isActive, 'is_active');
        }
        if (hasCosts !== undefined && hasCosts !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>hasCosts, 'has_costs');
        }
        if (applicationId !== undefined && applicationId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>applicationId, 'application_id');
        }
        if (sortColumn !== undefined && sortColumn !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortColumn, 'sort_column');
        }
        if (sortDirectionAscending !== undefined && sortDirectionAscending !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortDirectionAscending, 'sort_direction_ascending');
        }
        if (apiVersion !== undefined && apiVersion !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>apiVersion, 'api-version');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (OAuth2SecurityScheme) required
        localVarCredential = this.configuration.lookupCredential('OAuth2SecurityScheme');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json',
                'application/problem+json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.get<GetLicencesResponseDtoPaged>(`${this.configuration.basePath}/PalsoftLicence/${encodeURIComponent(String(pageNumber))}/${encodeURIComponent(String(pageSize))}`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Regenerate licence with new Identification code and Comment
     * @param licenceId Licence ID
     * @param apiVersion The requested API version
     * @param regenerateLicenceRequest Licence data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public bpmRegenerateLicence(licenceId: number, apiVersion: '3.0', regenerateLicenceRequest?: RegenerateLicenceRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'application/problem+json',}): Observable<RegenerateLicenceResponse>;
    public bpmRegenerateLicence(licenceId: number, apiVersion: '3.0', regenerateLicenceRequest?: RegenerateLicenceRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'application/problem+json',}): Observable<HttpResponse<RegenerateLicenceResponse>>;
    public bpmRegenerateLicence(licenceId: number, apiVersion: '3.0', regenerateLicenceRequest?: RegenerateLicenceRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'application/problem+json',}): Observable<HttpEvent<RegenerateLicenceResponse>>;
    public bpmRegenerateLicence(licenceId: number, apiVersion: '3.0', regenerateLicenceRequest?: RegenerateLicenceRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'application/problem+json',}): Observable<any> {
        if (licenceId === null || licenceId === undefined) {
            throw new Error('Required parameter licenceId was null or undefined when calling bpmRegenerateLicence.');
        }
        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling bpmRegenerateLicence.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (apiVersion !== undefined && apiVersion !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>apiVersion, 'api-version');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (OAuth2SecurityScheme) required
        localVarCredential = this.configuration.lookupCredential('OAuth2SecurityScheme');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json',
                'application/problem+json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.post<RegenerateLicenceResponse>(`${this.configuration.basePath}/PalsoftLicence/RegenerateLicence/${encodeURIComponent(String(licenceId))}`,
            regenerateLicenceRequest,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Set the status of the licence to active/disabled.
     * @param licenceId 
     * @param status 
     * @param apiVersion The requested API version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public bpmSetLicenceStatus(licenceId: number, status: 'Disabled' | 'Active', apiVersion: '3.0', observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json',}): Observable<any>;
    public bpmSetLicenceStatus(licenceId: number, status: 'Disabled' | 'Active', apiVersion: '3.0', observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json',}): Observable<HttpResponse<any>>;
    public bpmSetLicenceStatus(licenceId: number, status: 'Disabled' | 'Active', apiVersion: '3.0', observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json',}): Observable<HttpEvent<any>>;
    public bpmSetLicenceStatus(licenceId: number, status: 'Disabled' | 'Active', apiVersion: '3.0', observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/problem+json',}): Observable<any> {
        if (licenceId === null || licenceId === undefined) {
            throw new Error('Required parameter licenceId was null or undefined when calling bpmSetLicenceStatus.');
        }
        if (status === null || status === undefined) {
            throw new Error('Required parameter status was null or undefined when calling bpmSetLicenceStatus.');
        }
        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling bpmSetLicenceStatus.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (apiVersion !== undefined && apiVersion !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>apiVersion, 'api-version');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (OAuth2SecurityScheme) required
        localVarCredential = this.configuration.lookupCredential('OAuth2SecurityScheme');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/problem+json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/PalsoftLicence/Status/${encodeURIComponent(String(licenceId))}/${encodeURIComponent(String(status))}`,
            null,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update licence data
     * @param licenceId Licence ID
     * @param apiVersion The requested API version
     * @param updateLicenceRequest Licence data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public bpmUpdateLicence(licenceId: number, apiVersion: '3.0', updateLicenceRequest?: UpdateLicenceRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json',}): Observable<any>;
    public bpmUpdateLicence(licenceId: number, apiVersion: '3.0', updateLicenceRequest?: UpdateLicenceRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json',}): Observable<HttpResponse<any>>;
    public bpmUpdateLicence(licenceId: number, apiVersion: '3.0', updateLicenceRequest?: UpdateLicenceRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json',}): Observable<HttpEvent<any>>;
    public bpmUpdateLicence(licenceId: number, apiVersion: '3.0', updateLicenceRequest?: UpdateLicenceRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/problem+json',}): Observable<any> {
        if (licenceId === null || licenceId === undefined) {
            throw new Error('Required parameter licenceId was null or undefined when calling bpmUpdateLicence.');
        }
        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling bpmUpdateLicence.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (apiVersion !== undefined && apiVersion !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>apiVersion, 'api-version');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (OAuth2SecurityScheme) required
        localVarCredential = this.configuration.lookupCredential('OAuth2SecurityScheme');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/problem+json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/PalsoftLicence/${encodeURIComponent(String(licenceId))}`,
            updateLicenceRequest,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
