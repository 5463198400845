import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { SatisfactionConfigData } from '../../models/feedback-dialog-data';
import { SatisfactionFeedbackActions } from './satisfaction-feedback.actions';

const satisfactionFeedbackKey = 'satisfaction-feedback';

export interface SatisfactionFeedbackState {
  config: { [key: string]: SatisfactionConfigData };
  areConfigsLoading: { [key: string]: boolean };
}

export const satisfactionFeedbackState: SatisfactionFeedbackState = {
  config: {},
  areConfigsLoading: {},
};

const _satisfactionFeedback = createReducer(
  satisfactionFeedbackState,
  on(SatisfactionFeedbackActions.LoadUserSettings, (state, { key }) => ({
    ...state,
    areConfigsLoading: { ...state.areConfigsLoading, [key]: true },
  })),
  on(
    SatisfactionFeedbackActions.LoadUserSettingsSuccess,
    (state, { key, value }) => ({
      ...state,
      areConfigsLoading: { ...state.areConfigsLoading, [key]: false },
      config: { ...state.config, [key]: value },
    }),
  ),
  on(SatisfactionFeedbackActions.LoadUserSettingsFailed, (state, { key }) => ({
    ...state,
    areConfigsLoading: { ...state.areConfigsLoading, [key]: false },
  })),
  on(SatisfactionFeedbackActions.SetUserSettings, (state, { key, value }) => ({
    ...state,
    config: { ...state.config, [key]: value },
  })),
);

function _satisfactionFeedbackReducer(state, action) {
  return _satisfactionFeedback(state, action);
}

export const SatisfactionFeedbackFeature = createFeature({
  name: satisfactionFeedbackKey,
  reducer: _satisfactionFeedbackReducer,
  extraSelectors: ({ selectConfig, selectAreConfigsLoading }) => ({
    shouldShowFeedback: (key: string) =>
      createSelector(
        selectConfig,
        selectAreConfigsLoading,
        (config, areConfigsLoading) => {
          if (areConfigsLoading?.[key]) {
            return true;
          } else {
            const feedbackCount = config?.[key]?.count;
            const feedbackDate = config?.[key]?.date;
            const count = feedbackCount || 0;
            const date = new Date(
              feedbackDate || new Date().getTime().toString(),
            );

            if (count === 0) {
              return true;
            } else if (count === 1 && _isAfterDate(date, 14)) {
              return true;
            } else if (count === 2 && _isAfterDate(date, 90)) {
              return true;
            } else if (count === 3 && _isAfterDate(date, 365)) {
              return true;
            } else {
              return false;
            }
          }
        },
      ),
    shouldLoadUserSettings: (key: string) =>
      createSelector(selectConfig, (config) => !config?.[key]),
  }),
});

function _isAfterDate(date: Date, days: number): boolean {
  return new Date().getTime() - date.getTime() > days * 24 * 60 * 60 * 1000;
}
