import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Signal,
} from '@angular/core';
import { FlexLayoutModule, FlexModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { DsSpacingModule } from '@design-system/cdk/spacing';
import { TranslateModule } from '@ngx-translate/core';
import { ApiCategory } from '@paldesk/shared-lib/data-access/palipedia-service-generated';
import { GoogleAnalytics4DirectiveModule } from '@shared-lib/google-analytics';
import { AppWrapperService, HEADER_ITEM_DATA } from '../../app-wrapper.service';
import { DocumentType } from '../../model/document-type.model';
import { AppWrapperV2Store } from '../../signal-store/app-wrapper-v2-signal-store';
import { GridRowColumnStylePipe } from '../grid-row-column-style.pipe';
import { HeaderItemData, gAAppWrapperV2Constants } from '../model';

@Component({
  selector: 'ds-new-paldesk-documents-menu',
  templateUrl: './documents-menu.component.html',
  styleUrls: ['./documents-menu.component.css'],
  imports: [
    CommonModule,
    MatCardModule,
    FlexModule,
    FlexLayoutModule,
    MatIconModule,
    MatButtonModule,
    TranslateModule,
    DsSpacingModule,
    GridRowColumnStylePipe,
    GoogleAnalytics4DirectiveModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentsMenuComponent {
  documentCategories: Signal<ApiCategory[] | null>;
  public googleAnalyticsConstants = gAAppWrapperV2Constants;
  constructor(
    @Inject(HEADER_ITEM_DATA) public menuData: HeaderItemData<DocumentType>,
    public appWrapperService: AppWrapperService,
    private store: AppWrapperV2Store,
  ) {
    this.documentCategories =
      this.menuData.data === DocumentType.service
        ? this.store.serviceDocumentCategories
        : this.store.productCategories;
  }

  openInNew(code: string) {
    this.appWrapperService.openInNewTab(code, this.menuData.data);
    this.appWrapperService.closeOverlay();
  }
}
