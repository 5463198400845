import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { GoogleAnalytics4DirectiveModule } from '@shared-lib/google-analytics';
import { SafePipeModule } from '@shared-lib/safe-pipe';
import { SurveyModule } from '../survey.module';

export interface SurveyDialogData {
  surveyUrl: string;
  additionalQueryParams?: Map<string, string>;
}

@Component({
  selector: 'paldesk-survey-dialog',
  imports: [
    CommonModule,
    MatCardModule,
    SafePipeModule,
    SurveyModule,
    MatButtonModule,
    MatIconModule,
    GoogleAnalytics4DirectiveModule,
  ],
  templateUrl: './survey-dialog.component.html',
  styleUrls: ['./survey-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SurveyDialogComponent {
  @Input() data: SurveyDialogData;
  @Output() closed: EventEmitter<void> = new EventEmitter();
}
