import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { UserService } from '@features/auth';
import { filterTruthy } from '@shared-lib/rxjs';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { APPS } from '../app-roles';
import { PARTNERTYPES } from '@config';

@Injectable({
  providedIn: 'root',
})
export class PermissionGuard {
  constructor(
    private _userService: UserService,
    public router: Router,
  ) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this._userService.isAuthorized$.pipe(
      distinctUntilChanged(),
      filterTruthy(),
      map(() => {
        if (
          this._userService.hasOneRole([
            APPS.BUSINESS_PARTNER_MANAGEMENT.ROLES.ADMIN,
            APPS.BUSINESS_PARTNER_MANAGEMENT.ROLES.NETWORK_COMPANY_MANAGEMENT,
            APPS.BUSINESS_PARTNER_MANAGEMENT.ROLES.NETWORK_USER_MANAGEMENT,
            APPS.BUSINESS_PARTNER_MANAGEMENT.ROLES.SUPPLIER_MANAGEMENT,
            APPS.BUSINESS_PARTNER_MANAGEMENT.ROLES.PARTNER_NETWORK_READ_ONLY,
          ])
        ) {
          return this.router.parseUrl('/palfinger-partner');
        } else if (
          this._userService.hasOneRole([APPS.USER_MANAGEMENT.ROLES.USER])
        ) {
          return this.router.parseUrl('/user-management');
        } else if (
          this._userService.hasRole(
            APPS.BUSINESS_PARTNER_MANAGEMENT.ROLES.PALSOFT_USER,
          )
        ) {
          return this.router.parseUrl('/palsoft');
        } else if (
          this._userService.isOneOfPartnerTypes([
            PARTNERTYPES.AG,
            PARTNERTYPES.AREA,
            PARTNERTYPES.GENERAL_AGENT,
            PARTNERTYPES.SERVICE_PARTNER,
            PARTNERTYPES.SUPPLIER,
          ])
        ) {
          return this.router.parseUrl('/company-management');
        } else {
          return this.router.parseUrl('/special/permissiondenied');
        }
      }),
    );
  }
}
