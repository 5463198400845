@if ((title$ | async)?.length || store?.title()?.length) {
  <mat-card class="no-border-radius">
    <mat-card-content fxLayout="row" fxLayoutAlign="space-between center">
      <div
        data-cy="ds-page-action-section"
        fxLayout="row"
        fxLayout.lt-sm="column"
        [fxLayoutGap]="0.5 | dsSpacing"
        fxLayoutAlign.gt-sm="start center"
      >
        @if (media.isActive('gt-sm')) {
          <h1 class="default no-margin-bottom" data-cy="ds-page-action-title">
            {{
              (store?.title()?.length ? store?.title() : (title$ | async)) || ''
                | translate
            }}
          </h1>
        } @else {
          <h2 class="default no-margin-bottom" data-cy="ds-page-action-title">
            {{
              (store?.title()?.length ? store?.title() : (title$ | async)) || ''
                | translate
            }}
          </h2>
        }

        @if (store?.secondTitle()) {
          <span
            [innerHTML]="sanitizer.sanitize(1, store?.secondTitle() || '')"
            data-cy="ds-page-action-subtitle"
          ></span>
        }
      </div>
      @if (store?.actions()?.length) {
        @if (!store?.showInitialMoreButton() && media.isActive('gt-sm')) {
          <div fxLayout="row" data-cy="ds-page-action-actions">
            <button
              *pdLet="(store?.actions())![0] as action"
              mat-button
              (click)="action.callback()"
              [ngClass]="action?.class"
              [attr.data-cy]="action?.cypressTag"
              [dsButtonPending]="action?.isActionPending$ | async"
              [gaClickListener]="action?.googleAnalytics"
            >
              @if (action?.icon) {
                <mat-icon>{{ action.icon }}</mat-icon>
              }
              {{ action.name | translate }}
            </button>
            @if ((store?.actions())!.length > 1) {
              <button
                mat-button
                [matMenuTriggerFor]="menu"
                data-cy="ds-page-action-menu"
              >
                <mat-icon>add</mat-icon>
                {{ 'general.more_actions' | translate }}
              </button>
              <mat-menu #menu="matMenu">
                @for (action of store?.actions()?.slice(1); track action) {
                  <button
                    mat-menu-item
                    (click)="action.callback()"
                    [ngClass]="action?.class"
                    [attr.data-cy]="action?.cypressTag"
                    [dsButtonPending]="action?.isActionPending$ | async"
                    [gaClickListener]="action?.googleAnalytics"
                  >
                    @if (action?.icon) {
                      <mat-icon>{{ action.icon }}</mat-icon>
                    }
                    {{ action.name | translate }}
                  </button>
                }
              </mat-menu>
            }
          </div>
        } @else {
          <button
            mat-icon-button
            [matMenuTriggerFor]="menuAll"
            data-cy="ds-page-action-actions"
          >
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menuAll="matMenu">
            @for (action of store?.actions(); track action) {
              <button
                mat-menu-item
                (click)="action.callback()"
                [attr.data-cy]="action?.cypressTag"
                [dsButtonPending]="action?.isActionPending$ | async"
                [gaClickListener]="action?.googleAnalytics"
              >
                @if (action?.icon) {
                  <mat-icon>{{ action.icon }}</mat-icon>
                }
                {{ action.name | translate }}
              </button>
            }
          </mat-menu>
        }
      }
    </mat-card-content>
  </mat-card>
}
