import { MediaChange } from '@angular/flex-layout';
import { UserContext } from '@features/auth';

export interface HeaderItemData<T> {
  data: T;
}

class GAClickListener {
  static navigateTo = 'navigate_to_';
  static navigateToDocument = 'navigate_to_document_';
  static navigateToDashboard = 'navigate_to_dashboard';
  static userMenu = 'show_profile';
  static navigateToIdentityProfile =
    this.userMenu + '_' + 'navigate_to_is_profile';
  static navigateToPat = this.userMenu + '_' + 'navigate_to_pat';
  static openPrivacy = this.userMenu + '_' + 'open_privacy_policy';
  static openTos = this.userMenu + '_' + 'open_tos';
  static logout = this.userMenu + '_' + 'logout';
  static showApps = 'show_apps';
  static showProducts = 'show_products';
  static showServiceDocuments = 'show_service_documents';
  static showHelp = 'show_help';
  static openFreshdeskHelp = this.showHelp + '_' + 'open_freshdesk_help';
  static navigateToPaldeskSupport =
    this.showHelp + '_' + 'navigate_to_paldesk_status';
  static openFreshdeskIdea = this.showHelp + '_' + 'open_freshdesk_idea';
  static openFeedback = this.showHelp + '_' + 'open_feedback';
  static navigateToAppHelp = this.showHelp + '_' + 'navigate_to_app_help';
  static navigateToAppNews = this.showHelp + '_' + 'navigate_to_app_news';
  static openAppInNewTabToggle = 'open_app_in_new_tab_toggle';
  static searchSuggestion = 'search_suggestion';
  static searchHistory = 'search_history';
  static searchHistoryDelete = 'search_history_delete';
}

class OtherEvents {
  static search = 'search';
}

class AppWrapperV2Header {
  static headerName = 'app_wrapper_v2_header';
  static clickListener = GAClickListener;
  static otherEvents = OtherEvents;
}

class AppWrapperV2Sidebar {
  static sidebarName = 'app_wrapper_v2_sidebar_mobile';
  static clickListener = GAClickListener;
}

class GAAppWrapperV2Constants {
  static header = AppWrapperV2Header;
  static sidebar = AppWrapperV2Sidebar;
}

export const gAAppWrapperV2Constants = GAAppWrapperV2Constants;

export interface CalculateViewportData {
  media: MediaChange[];
  currentUser: UserContext;
}

export class ProblemDetails {
  type: string | undefined;
  title: string | undefined;
  status: number | undefined;
  detail: string | undefined;
  instance: string | undefined;

  /// Validation errors from backend, mapped to dictionary for easier usage inside html templates
  get errorDescription(): Record<string, string[]> | undefined {
    if (this['errors'] && Object.keys(this['errors'].length)) {
      return Object.keys(this['errors']).reduce((acc, key) => {
        acc[key] = this['errors'][key];
        return acc;
      }, {});
    }
    return undefined;
  }
}
