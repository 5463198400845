import { Component, Input } from '@angular/core';

interface IconModel {
  url: string;
  width: number;
  height: number;
}

class Icon implements IconModel {
  url = '';
  width = 0;
  height = 32;

  constructor(filename: string, width?: number) {
    this.url = '/assets/img/product-icons/' + filename;
    this.width = width || 50;
  }
}

@Component({
  selector: 'ds-equipment-icon',
  template:
    '<img [style.width.px]="icon.width" [style.max-width.px]="maxHeight" [src]="icon.url" alt="equipment icon"> ',
  styles: [
    ':host{justify-content: center;align-items: center;margin-right: 8px;place-content: start;align-items: center;}',
  ],
  standalone: false,
})
export class DsEquipmentIconComponent {
  @Input() type: string;
  @Input()
  hasTelematic = false;
  @Input()
  width?: number;
  @Input()
  maxHeight = 32;

  // eslint-disable-next-line sonarjs/cognitive-complexity
  get icon(): Icon {
    // eslint-disable-next-line sonarjs/max-switch-cases
    switch (this.type) {
      case 'crane':
      case '001':
        return new Icon(
          this.hasTelematic
            ? '001_Loader_Cranes_Connected.png'
            : '001_Loader_Cranes.png',
          this.width ? this.width : undefined,
        );
      case 'railway':
      case '002':
        return new Icon('002_Railway.png', this.width ? this.width : undefined);
      case 'railway_platforms':
      case 'platforms':
      case '003':
        return new Icon(
          this.hasTelematic
            ? '003_Access_Platforms_Connected.png'
            : '003_Access_Platforms.png',
          this.width ? this.width : undefined,
        );
      case '004':
        return new Icon(
          this.hasTelematic
            ? '004_Truck_Mounted_Forklifts_Connected.png'
            : '004_Truck_Mounted_Forklifts.png',
          this.width ? this.width : undefined,
        );

      case 'taillift':
      case 'TLI':
      case '006':
        return new Icon(
          this.hasTelematic
            ? '006_Tail Lifts_Connected.png'
            : '006_Tail Lifts.png',
          this.width ? this.width : undefined,
        );
      case 'hookloader':
      case '007':
        return new Icon(
          this.hasTelematic
            ? '007_Container_Handling_Systems_Connected.png'
            : '007_Container_Handling_Systems.png',
          this.width ? this.width : undefined,
        );
      case 'epsilon':
      case '010':
        return new Icon(
          this.hasTelematic
            ? '010_Epsilon_Timber_and_Recycling_Connected.png'
            : '010_Epsilon_Timber_and_Recycling.png',
          this.width ? this.width : undefined,
        );
      case 'skiploader':
      case '011':
        return new Icon(
          '011_Skiploader.png',
          this.width ? this.width : undefined,
        );
      case '013':
        return new Icon(
          '013_Marine cranes.png',
          this.width ? this.width : undefined,
        );
      case '026':
        return new Icon(
          '026_Wind cranes.png',
          this.width ? this.width : undefined,
        );
      case '030':
        return new Icon(
          '030_Epsilon_Offroad.png',
          this.width ? this.width : undefined,
        );
      case '031':
        return new Icon(
          '031_Service_Cranes_NA.png',
          this.width ? this.width : undefined,
        );
      case '032':
        return new Icon(
          '032_Truck_bodies_NA_update_2018.png',
          this.width ? this.width : undefined,
        );
      case '034':
        return new Icon(
          '034_PAL_PRO_Mechanic_Trucks_NA.png',
          this.width ? this.width : undefined,
        );
      case '035':
        return new Icon(
          '035_Large_Aerial_Platforms_NA.png',
          this.width ? this.width : undefined,
        );
      case '036':
        return new Icon(
          '036_Cable_Hoists_NA.png',
          this.width ? this.width : undefined,
        );
      case '037':
        return new Icon(
          '037_Hoists_NA.png',
          this.width ? this.width : undefined,
        );
      case '039':
        return new Icon(
          '039_Pick_up_Lifts_NA.png',
          this.width ? this.width : undefined,
        );
      case '040':
        return new Icon(
          this.hasTelematic
            ? '040_Access_Platform_PPI_Smart_Connected.png'
            : '040_Access_Platform_PPI_Smart.png',
          this.width ? this.width : undefined,
        );
      case 'crawlercrane':
      case '043':
        return new Icon(
          this.hasTelematic
            ? '043_Crawler_Crane_Connected.png'
            : '043_Crawler_Crane.png',
          this.width ? this.width : undefined,
        );
      case '044':
        return new Icon(
          '044_Passenger_Lifts_Update.png',
          this.width ? this.width : undefined,
        );
      case '045':
      case '042':
        return new Icon(
          '045_Bridge_Inspection_Units.png',
          this.width ? this.width : undefined,
        );
      case '046':
        return new Icon(
          '046_Offshore cranes.png',
          this.width ? this.width : undefined,
        );
      case '047':
        return new Icon(
          '047_Lifesaving equipment.png',
          this.width ? this.width : undefined,
        );
      case '048':
        return new Icon(
          '048_Life and rescue boats.png',
          this.width ? this.width : undefined,
        );
      case '049':
        return new Icon('049_Winches.png', this.width ? this.width : undefined);
      case '050':
        return new Icon(
          '050_Forestry_and_Scrap_Cranes_NA.png',
          this.width ? this.width : undefined,
        );
      case '052':
        return new Icon('052_Inman.png', this.width ? this.width : undefined);
      case '053':
      case '056':
      case '057':
        return new Icon(
          '053_ETI_Solutions_NA.png',
          this.width ? this.width : undefined,
        );
      case '054':
        return new Icon('054_Davits.png', this.width ? this.width : undefined);
      case '101':
        return new Icon(
          '101_equipment.svg',
          this.width ? this.width : undefined,
        );
      case '102':
        return new Icon(
          '102_turnkey_solution.svg',
          this.width ? this.width : undefined,
        );

      default:
        return new Icon(
          '055_uncategorized.jpg',
          this.width ? this.width : undefined,
        );
    }
  }
}
