import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from '@features/auth';

@Component({
  selector: 'ds-signout',
  templateUrl: './signout.component.html',
  styleUrls: ['../shared.scss', './signout.component.scss'],
  standalone: false,
})
export class DsSignoutComponent implements OnInit {
  constructor(
    public userService: UserService,
    private dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.userService.logoutLocally();
    this.dialog.closeAll();
  }

  relogin() {
    this.userService.login();
  }
}
