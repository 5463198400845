/**
 * v3 - Business Partner Management
 * Business Partner Management API
 *
 * The version of the OpenAPI document: 3.0
 * Contact: m.kollar@palfinger.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ApplicationRoleRequest } from './applicationRoleRequest';


export interface ApplicationRequest { 
    
    id?: string;
    
    key?: string;
    
    category_id: number;
    
    name: string;
    
    is_favorite: boolean;
    
    icon?: string;
    
    url: string;
    
    tags?: string;
    
    is_hidden: boolean;
    
    product_owner?: string;
    
    support_emails?: Array<string>;
    
    roles?: Array<ApplicationRoleRequest>;
    
    allowed_partner_types?: Array<ApplicationRequestAllowedPartnerTypes>;
}
export enum ApplicationRequestAllowedPartnerTypes {
    AG = 'AG',
    AREA = 'AREA',
    Dealer = 'Dealer',
    SubDealer = 'SubDealer',
    EndCustomer = 'EndCustomer',
    Supplier = 'Supplier'
};



