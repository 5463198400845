import { Component, forwardRef, Input } from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
import {
  MessageSeverityType,
  MessageTargetType,
  MessengerService,
} from '@shared-lib/messenger';
import { PalfingerValidators } from '@utils/palfinger-validators';

@Component({
  selector: 'ui-kit-confirm-email',
  templateUrl: './confirm-email.component.html',
  styleUrls: ['./confirm-email.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ConfirmEmailComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ConfirmEmailComponent),
      multi: true,
    },
  ],
  standalone: false,
})
export class ConfirmEmailComponent implements ControlValueAccessor, Validator {
  @Input() hint: string;

  emailForm: UntypedFormGroup;
  emailErrorMatcher: ErrorStateMatcher = {
    isErrorState(control: UntypedFormControl | null): boolean {
      const invalidCtrl = !!(control && control.invalid && control.touched);
      const invalidParent = !!(
        control &&
        control.parent &&
        control.touched &&
        control.parent.errors &&
        control.parent.errors.notEquivalent
      );

      return invalidCtrl || invalidParent;
    },
  };

  constructor(
    private fb: UntypedFormBuilder,
    private messengerService: MessengerService,
    private translateService: TranslateService,
  ) {
    this.emailForm = this.fb.group(
      {
        email: [
          '',
          {
            validators: [Validators.email],
            updateOn: 'blur',
          },
        ],
        confirm_email: [],
      },
      {
        validator: PalfingerValidators.matchingEqual('email', 'confirm_email'),
      },
    );
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    this.emailForm.valueChanges.subscribe((val) => this.onChange());
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  propagateChange = (_: any) => {
    return;
  };

  writeValue(obj: any): void {
    this.emailForm.controls.email.setValue(obj);
    this.emailForm.controls.confirm_email.setValue(obj);
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  registerOnTouched(fn: any): void {
    return;
  }

  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  onChange() {
    this.propagateChange(this.emailForm.controls.email.value);
  }

  validate(): ValidationErrors | null {
    return this.emailForm.valid
      ? null
      : {
          confirmEmail: true,
        };
  }

  onTryToCopy() {
    this.messengerService.sendDetailMessage({
      message: this.translateService.instant('general.copy_not_allowed'),
      severity: MessageSeverityType.warning,
      targets: MessageTargetType.toast,
    });
  }
}
