import { Injectable } from '@angular/core';
import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';
import { PageActionsConfiguration } from './model';

const initialState: PageActionsConfiguration = {
  title: null,
  secondTitle: null,
  actions: null,
  showInitialMoreButton: null,
};

@Injectable({
  providedIn: 'root',
})
export class PageActionsStore extends signalStore(
  { protectedState: false },
  withState(initialState),
  withMethods((state) => ({
    updatePageActionsConfiguration(value: PageActionsConfiguration) {
      return patchState(state, value);
    },
    clearPageActionsConfiguration() {
      return patchState(state, initialState);
    },
  })),
) {}
