import {
  Component,
  ElementRef,
  forwardRef,
  Input,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { CountryResponse } from '@data-access/bpm-generated';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'ui-kit-phone-code-selection',
  templateUrl: './phone-code-selection.component.html',
  styleUrls: ['./phone-code-selection.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PhoneCodeSelectionComponent),
      multi: true,
    },
  ],
  standalone: false,
})
export class PhoneCodeSelectionComponent implements ControlValueAccessor {
  @Input() required: boolean;
  @Input() loading: boolean;
  @Input() placeholder = 'Phone code';

  @ViewChild('filter_control_el', { static: true })
  filter_control_el: ElementRef;

  @Input() set value(val: string) {
    if (val) {
      this.phone_code.setValue(val);
    }
  }

  filteredCountries: CountryResponse[];
  filter_control = new UntypedFormControl();
  phone_code = new UntypedFormControl({ value: '', disabled: true });

  @Input() set countries(val: CountryResponse[]) {
    if (val) {
      this.countries_ = val.slice();
      this.filter_control.valueChanges
        .pipe(
          startWith(''),
          map((value) =>
            typeof value === 'string'
              ? this._filter(value)
              : this.countries.slice(),
          ),
        )
        .subscribe((val_) => {
          this.filteredCountries = val_;
        });
      this.phone_code.enable();
    } else {
      this.phone_code.disable();
    }
  }

  private countries_: CountryResponse[];

  optionSelected(event: any) {
    this.propagateChange(event.option.value);
  }

  // CONTROL VALUE ACCESSOR
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  propagateChange = (_: any) => {
    return;
  };

  writeValue(obj: any): void {
    if (obj) {
      this.value = obj;
    }
  }
  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  registerOnTouched(_fn: any): void {
    return;
  }

  onChange(event: any) {
    this.propagateChange(event.value);
  }

  displayFn(code?: string): string | undefined {
    return code ? '+' + code : undefined;
  }

  onSelectOpen() {
    this.filter_control.patchValue(''); //reset filtered countries
    if (!this.phone_code.value) {
      this.filter_control_el.nativeElement.focus();
    }
  }

  _filter(name: string): CountryResponse[] {
    const filterValue = name.toLowerCase().replace('+', '');

    return this.countries_.filter(
      (country) =>
        (country.name &&
          country.name.toLowerCase().indexOf(filterValue) > -1) ||
        (country.phone_code &&
          country.phone_code.toLowerCase().indexOf(filterValue) > -1),
    );
  }
}
