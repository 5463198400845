import { Injectable } from '@angular/core';
import {
  DisabledPositionType,
  TableSettings,
  TableSettingsService,
} from '@design-system/components/table-settings';
import { Observable } from 'rxjs';

@Injectable()
export class DisplayColumnsSettingsService {
  constructor(private tableSettingsService: TableSettingsService) {}

  mapToTableSettings(
    columns: string[],
    uneditableColumns: string[],
  ): TableSettings[] {
    return columns.map((column) => {
      const res: TableSettings = {
        name: column,
        selected: true,
      };

      if (uneditableColumns && uneditableColumns.find((x) => x === column))
        res.disabledPosition = DisabledPositionType.Keep;

      if (column === 'actions') res.disabledPosition = DisabledPositionType.End;

      return res;
    });
  }

  getUserColumnsSettings(
    key: string,
    defaultColumns: string[],
    uneditableColumns: string[],
  ): Observable<TableSettings[]> {
    const defaultTableSettings = this.mapToTableSettings(
      defaultColumns,
      uneditableColumns,
    );
    return this.tableSettingsService.get(key, defaultTableSettings);
  }
}
