import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Signal } from '@angular/core';
import { FlexLayoutModule, FlexModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DsSpacingModule } from '@design-system/cdk/spacing';
import { Application, ApplicationContainer } from '@features/applications';
import { TranslateModule } from '@ngx-translate/core';
import { GoogleAnalytics4DirectiveModule } from '@shared-lib/google-analytics';
import { AppShortNamePipeModule } from '@utils/app-short-name-pipe';
import { AppWrapperService } from '../app-wrapper.service';
import { RemovePlaceholderDescriptions } from '../pipes/remove-placeholder-descriptions.pipe';
import { DsDefaultImageDirective } from '../shared/default-image.directive';
import { gAAppWrapperV2Constants } from '../shared/model';
import { OpenAppInNewTabComponent } from '../shared/open-app-in-new-tab/open-app-in-new-tab.component';
import { AppWrapperV2Store } from '../signal-store/app-wrapper-v2-signal-store';
import { OrderCategoriesPipe } from './order-categories.pipe';

export const imports = [
  CommonModule,
  TranslateModule,
  MatCardModule,
  MatIconModule,
  MatButtonModule,
  MatListModule,
  MatProgressSpinnerModule,
  FlexModule,
  FlexLayoutModule,
  DsSpacingModule,
  AppShortNamePipeModule,
  OrderCategoriesPipe,
  RemovePlaceholderDescriptions,
  GoogleAnalytics4DirectiveModule,
  DsDefaultImageDirective,
  OpenAppInNewTabComponent,
];

@Component({
  selector: 'ds-new-paldesk-apps-menu',
  imports: [imports],
  templateUrl: './apps-menu.component.html',
  styleUrls: ['./apps-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppsMenuComponent {
  applications: Signal<ApplicationContainer | null>;
  toggleOpenInNewTabSignal: Signal<boolean | null>;

  public googleAnalyticsConstants = gAAppWrapperV2Constants;
  constructor(
    public service: AppWrapperService,
    private _store: AppWrapperV2Store,
  ) {
    this.toggleOpenInNewTabSignal = this._store.appsNewTabUserSetting;
    this.applications = _store.applications;
  }

  getRow(index: number, columnCount: number): number {
    return Math.floor(index / columnCount) + 1;
  }

  // Function to calculate the column number based on the item index
  getColumn(index: number, columnCount: number): number {
    return (index % columnCount) + 1;
  }

  toggleFavorite(app?: Application): void {
    if (app) {
      app.isUpdatingFavorite = true;
      if (app.favorite) {
        this._store.removeFavorite(app.id);
      } else {
        this._store.addFavorite(app);
      }
    }
  }
}
