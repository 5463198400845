/**
 * v3 - Business Partner Management
 * Business Partner Management API
 *
 * The version of the OpenAPI document: 3.0
 * Contact: m.kollar@palfinger.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ApplicationRolesResponse } from './applicationRolesResponse';
import { ResponsiblePerson } from './responsiblePerson';
import { CommissionerResponse } from './commissionerResponse';


export interface PartnerUserResponse { 
    
    id: number;
    
    first_name: string;
    
    last_name: string;
    
    user_name?: string;
    
    email_address?: string;
    
    last_login?: Date;
    
    state: PartnerUserResponseState;
    
    company_name?: string;
    
    company_sap_id?: string;
    
    company_bpm_id: number;
    
    commissioner?: CommissionerResponse;
    
    application_roles: Array<ApplicationRolesResponse>;
    
    job_ids: Array<number>;
    
    can_be_deleted: boolean;
    
    is_responsible_person: boolean;
    
    is_education_manager: boolean;
    
    mobile_phone_number?: string;
    
    landline_phone_number?: string;
    
    responsible_person?: ResponsiblePerson;
    
    created_on: Date;
    
    created_by: string;
    
    modified_on: Date;
    
    modified_by: string;
    
    sap_user_id?: string;
    
    support_ticket_number?: string;
}
export enum PartnerUserResponseState {
    Active = 'Active',
    NoPermissions = 'NoPermissions',
    PendingActivation = 'PendingActivation',
    Blocked = 'Blocked'
};



