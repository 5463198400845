import { Injectable } from '@angular/core';
import { PARTNERTYPES } from '@config';
import { UserService } from '@features/auth';
import { APPS } from '../shared/app-roles';

@Injectable()
export class AddUserGuard {
  constructor(private userService: UserService) {}

  isCustomerManagerOrPartner(): boolean {
    return (
      !this.userService.isOneOfPartnerTypes([PARTNERTYPES.END_CUSTOMER]) ||
      this.userService.hasRole(APPS.USER_MANAGEMENT.ROLES.USER)
    );
  }
}
