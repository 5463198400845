import {
  FlexibleConnectedPositionStrategy,
  HorizontalConnectionPos,
  Overlay,
  OverlayConfig,
  OverlayRef,
  VerticalConnectionPos,
} from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { dsConfig } from '@design-system/cdk/config';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { SpotlightActions } from './shared/spotlight.actions';
import { SpotlightState } from './shared/spotlight.reducer';
import { SpotlightSelectors } from './shared/spotlight.selectors';
import { DS_DISABLE_SPOTLIGHTS } from './spotlight-token';

@Component({
  selector: 'ds-spotlight',
  templateUrl: './spotlight.component.html',
  styleUrls: ['./spotlight.component.scss'],
  standalone: false,
})
export class DsSpotlightComponent implements AfterViewInit, OnDestroy, OnInit {
  @Input() id: string;
  // tslint:disable-next-line:max-union-size
  @Input() position: 'left' | 'right' | 'above' | 'below' = 'left';
  @Input() tags: string[];
  @Input() animation: 'none' | 'performance' | 'christmas' = 'none';
  @Input() disableBackdropClose = false;

  @ViewChild(TemplateRef) templateRef: TemplateRef<any>;

  index$: Observable<number>;
  spotlightsTotal$: Observable<number>;

  // set by directive
  element?: ElementRef<HTMLElement>;

  private _isFirstWithThisId = false;
  private _isOpen = false;
  private _portal: TemplatePortal;
  private _overlayRef: OverlayRef | null = null;
  private readonly destroy$ = new Subject<void>();
  constructor(
    private _overlay: Overlay,
    private _viewContainerRef: ViewContainerRef,
    private _store: Store<SpotlightState>,
    @Optional()
    @Inject(DS_DISABLE_SPOTLIGHTS)
    private _disableSpotlights: boolean,
  ) {}

  ngOnInit() {
    if (!this._disableSpotlights) {
      this._store
        .select(SpotlightSelectors.isSpotLightRegistered(this.id))
        .pipe(take(1))
        .subscribe((isSpotLightRegistered) => {
          if (!isSpotLightRegistered) {
            this._isFirstWithThisId = true;
            this._store.dispatch(
              SpotlightActions.RegisterSpotlight({
                id: this.id,
                tags: this.tags,
              }),
            );
          }
        });
    }
  }

  ngAfterViewInit() {
    this.index$ = this._store
      .select(SpotlightSelectors.indexById(this.id))
      .pipe(map((x) => x + 1));
    this.spotlightsTotal$ = this._store.select(
      SpotlightSelectors.spotlightsTotal,
    );

    this._store
      .select(SpotlightSelectors.isSpotLightOpen(this.id))
      .pipe(
        filter(() => this._isFirstWithThisId),
        takeUntil(this.destroy$),
      )
      .subscribe((isSpotLightOpen) => {
        if (isSpotLightOpen && !this._isOpen) {
          this._open();
        } else if (!isSpotLightOpen && this._isOpen) {
          this._close();
        }
      });
  }

  onPreviousClick() {
    this._store.dispatch(SpotlightActions.OpenPrevious());
  }

  onNextClick() {
    this._store.dispatch(SpotlightActions.OpenNext());
  }

  onCloseClick() {
    this._store.dispatch(SpotlightActions.Close());
    this._close();
  }

  ngOnDestroy() {
    this._store?.dispatch(
      SpotlightActions.UnregisterSpotlight({ id: this.id }),
    );
    this.destroy$.next();
    this.destroy$.complete();
  }

  private _open() {
    if (!this._disableSpotlights) {
      this._overlayRef = this._createOverlay();
      this._isOpen = true;
    }
  }

  private _close() {
    if (this._overlayRef) {
      this._overlayRef.dispose();
    }
    this._isOpen = false;
  }

  /**
   * This method creates the overlay from the provided menu's template and saves its
   * OverlayRef so that it can be attached to the DOM when open is called.
   */
  private _createOverlay(): OverlayRef {
    const overlayConfig = this._getOverlayConfig(this.element);
    const overlayRef = this._overlay.create(overlayConfig);
    if (this.element) {
      this._setPosition(
        overlayConfig.positionStrategy as FlexibleConnectedPositionStrategy,
      );
    }

    overlayRef.attach(this._getPortal());

    overlayRef
      .backdropClick()
      .pipe(
        take(1),
        filter(() => !this.disableBackdropClose),
      )
      .subscribe(() => {
        this._store.dispatch(SpotlightActions.Close());
        this._close();
      });
    return overlayRef;
  }

  /**
   * This method builds the configuration object needed to create the overlay, the OverlayState.
   * @returns OverlayConfig
   */
  private _getOverlayConfig(element?: ElementRef): OverlayConfig {
    if (element) {
      // similar to tooltip
      return new OverlayConfig({
        positionStrategy: this._overlay
          .position()
          .flexibleConnectedTo(element)
          .withLockedPosition(),
        disposeOnNavigation: true,
        hasBackdrop: true,
        backdropClass: 'cdk-overlay-transparent-backdrop',
      });
    } else {
      // similar to dialog
      return new OverlayConfig({
        positionStrategy: this._overlay
          .position()
          .global()
          .centerHorizontally()
          .centerVertically(),
        maxWidth: '80vw',
        maxHeight: '100%',
        disposeOnNavigation: true,
        hasBackdrop: true,
      });
    }
  }

  /** Gets the portal that should be attached to the overlay. */
  private _getPortal(): TemplatePortal {
    if (!this._portal || this._portal.templateRef !== this.templateRef) {
      this._portal = new TemplatePortal(
        this.templateRef,
        this._viewContainerRef,
      );
    }

    return this._portal;
  }

  /**
   * Sets the appropriate positions on a position strategy
   * so the overlay connects with the trigger correctly.
   * @param positionStrategy Strategy whose position to update.
   */
  // eslint-disable-next-line sonarjs/cognitive-complexity
  private _setPosition(positionStrategy: FlexibleConnectedPositionStrategy) {
    let originX: HorizontalConnectionPos = 'end';
    let originY: VerticalConnectionPos = 'top';
    let overlayX: HorizontalConnectionPos = 'end';
    let overlayY: VerticalConnectionPos = 'top';
    let offsetY = 0;
    let offsetX = 0;
    const width =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    if (this.position === 'above' || this.position === 'below') {
      originX = 'center';
      overlayX = 'center';
      originY = this.position === 'above' ? 'top' : 'bottom';
      overlayY = this.position === 'above' ? 'bottom' : 'top';
      offsetY =
        this.position === 'above' ? -dsConfig.spacing : dsConfig.spacing;
    } else if (this.position === 'left' || this.position === 'right') {
      originY = 'top';
      overlayY = 'top';
      originX = this.position === 'left' ? 'start' : 'end';
      overlayX = this.position === 'left' ? 'end' : 'start';
      offsetX = this.position === 'left' ? -dsConfig.spacing : dsConfig.spacing;
    }
    if (
      width < 600 &&
      (this.position === 'left' || this.position === 'right')
    ) {
      offsetY = 4 * dsConfig.spacing;
      offsetX = 0;
    }

    const originFallback = this._invertPosition(
      originX,
      originY,
      this.position,
    );
    const overlayFallback = this._invertPosition(
      overlayX,
      overlayY,
      this.position,
    );

    positionStrategy.withPositions([
      { originX, originY, overlayX, overlayY, offsetX, offsetY },
      {
        originX: originFallback.x,
        originY,
        overlayX: overlayFallback.x,
        overlayY,
        offsetX: -offsetX,
        offsetY,
      },
      {
        originX,
        originY: originFallback.y,
        overlayX,
        overlayY: overlayFallback.y,
        offsetX: offsetX,
        offsetY: -offsetY,
      },
      {
        originX: originFallback.x,
        originY: originFallback.y,
        overlayX: overlayFallback.x,
        overlayY: overlayFallback.y,
        offsetX: -offsetX,
        offsetY: -offsetY,
      },
    ]);
  }

  /** Inverts an overlay position. */
  private _invertPosition(
    x: HorizontalConnectionPos,
    y: VerticalConnectionPos,
    position: string,
  ) {
    if (position === 'above' || position === 'below') {
      if (y === 'top') {
        y = 'bottom';
      } else if (y === 'bottom') {
        y = 'top';
      }
    } else {
      if (x === 'end') {
        x = 'start';
      } else if (x === 'start') {
        x = 'end';
      }
    }

    return { x, y };
  }
}
