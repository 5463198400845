<form [formGroup]="emailForm" fxLayout="column" fxLayoutGap="1rem grid">
  <mat-form-field
    fxFlex
    class="full-width"
    [ngClass]="{ 'multi-line-hint': hint }"
  >
    <mat-label>{{ 'general.email' | translate }}</mat-label>
    <input
      matInput
      formControlName="email"
      required
      uiKitPreventCopy
      (triedToCopy)="onTryToCopy()"
      data-cy="email"
    />
    <mat-hint *ngIf="hint" align="end" [innerHtml]="hint"> </mat-hint>
  </mat-form-field>
  <mat-form-field fxFlex class="full-width">
    <mat-label>{{ 'confirm_email.confirm_email' | translate }}</mat-label>
    <input
      matInput
      #emailConfirmInput
      formControlName="confirm_email"
      required
      [errorStateMatcher]="emailErrorMatcher"
      data-cy="confirm_email"
    />
    <mat-error *ngIf="emailConfirmInput.value.length">{{
      'confirm_email.email_should_match' | translate
    }}</mat-error>
  </mat-form-field>
</form>
