import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Routes } from '@angular/router';
import { PARTNERTYPES } from '@config';
import { AuthGuard, AuthPermissions } from '@features/auth';
import { AddUserGuard } from './add-user/add-user.guard';
import { APPS } from './shared/app-roles';
import { PermissionGuard } from './shared/guards/permission.guard';
import { SpotlightResetComponent } from './spotlight-reset/spotlight-reset.component';

export const BPM_NETWORK_EDITORS = [
  APPS.BUSINESS_PARTNER_MANAGEMENT.ROLES.NETWORK_COMPANY_MANAGEMENT,
  APPS.BUSINESS_PARTNER_MANAGEMENT.ROLES.NETWORK_USER_MANAGEMENT,
  APPS.BUSINESS_PARTNER_MANAGEMENT.ROLES.SUPPLIER_MANAGEMENT,
  APPS.BUSINESS_PARTNER_MANAGEMENT.ROLES.ADMIN,
];

export const appRoutes: Routes = [
  {
    path: '',
    canActivate: [
      (next: ActivatedRouteSnapshot) => inject(AuthGuard).canActivate(next),
    ],
    children: [
      {
        path: '',
        canActivate: [() => inject(PermissionGuard).canActivate()],
        pathMatch: 'full',
      },
      {
        path: 'palfinger-partner',
        loadChildren: () =>
          import('./palfinger-partner/palfinger-partner.module').then(
            (m) => m.PalfingerPartnerModule,
          ),
        canMatch: [(next: Route) => inject(AuthGuard).canMatch(next)],
        data: {
          permissions: {
            roles: [
              ...BPM_NETWORK_EDITORS,
              APPS.BUSINESS_PARTNER_MANAGEMENT.ROLES.PARTNER_NETWORK_READ_ONLY,
            ],
          } as AuthPermissions,
          breadcrumb: 'bpm.apps.create_partner',
        },
      },
      {
        path: 'user-management',
        pathMatch: 'full',
        loadChildren: () =>
          import('./user-management/user-management.module').then(
            (m) => m.UserManagementModule,
          ),
        canActivate: [
          (next: ActivatedRouteSnapshot) => inject(AuthGuard).canActivate(next),
        ],
        data: {
          permissions: {
            roles: [APPS.USER_MANAGEMENT.ROLES.USER],
            partnerTypes: [PARTNERTYPES.END_CUSTOMER],
          } as AuthPermissions,
          breadcrumb: 'bpm.apps.user_management',
        },
      },
      {
        path: 'company-management',
        loadChildren: () =>
          import('./company-management/company-management.module').then(
            (m) => m.CompanyManagementModule,
          ),
        canActivate: [
          (next: ActivatedRouteSnapshot) => inject(AuthGuard).canActivate(next),
        ],
        data: {
          permissions: {
            partnerTypes: [
              PARTNERTYPES.AG,
              PARTNERTYPES.AREA,
              PARTNERTYPES.GENERAL_AGENT,
              PARTNERTYPES.SERVICE_PARTNER,
              PARTNERTYPES.SUPPLIER,
            ],
          } as AuthPermissions,
          breadcrumb: 'bpm.apps.company_management',
        },
      },
      {
        path: 'add-user',
        loadChildren: () =>
          import('./add-user/add-user.module').then((m) => m.AddUserModule),
        canMatch: [() => inject(AddUserGuard).isCustomerManagerOrPartner()],
        data: {
          breadcrumb: 'bpm.apps.invite_user',
        },
      },
      {
        path: 'content-management',
        loadChildren: () =>
          import('./content-management/content-management.module').then(
            (m) => m.ContentManagementModule,
          ),
        canActivate: [
          (next: ActivatedRouteSnapshot) => inject(AuthGuard).canActivate(next),
        ],
        data: {
          permissions: {
            roles: [APPS.BUSINESS_PARTNER_MANAGEMENT.ROLES.ADMIN],
          } as AuthPermissions,
          breadcrumb: 'bpm.apps.content_management',
        },
      },
      {
        path: 'palsoft',
        loadChildren: () =>
          import('./palsoft/palsoft.module').then((m) => m.PalsoftModule),
        canMatch: [(next: Route) => inject(AuthGuard).canMatch(next)],
        data: {
          permissions: {
            roles: [APPS.BUSINESS_PARTNER_MANAGEMENT.ROLES.PALSOFT_USER],
          } as AuthPermissions,
          breadcrumb: 'bpm.apps.palsoft',
        },
      },
      {
        path: 'spotlight-reset',
        component: SpotlightResetComponent,
        data: {
          breadcrumb: 'bpm.spotlight.reset',
        },
      },
      {
        path: 'help',
        loadChildren: () =>
          import('./help/help-pages.module').then((m) => m.HelpPagesModule),
        data: {
          breadcrumb: 'general.help',
        },
      },
      {
        path: 'release-notes',
        loadChildren: () =>
          import('./release-notes/release-notes.module').then(
            (m) => m.ReleasNotesModule,
          ),
      },
    ],
  },
  {
    path: 'callback',
    children: [],
  },
  {
    path: 'special',
    loadChildren: () =>
      import('@design-system/feature/special-pages').then(
        (m) => m.DsSpecialPagesModule,
      ),
  },
  {
    path: '**',
    redirectTo: 'special',
  },
];
