<mat-form-field class="full-width">
  <mat-progress-spinner
    *ngIf="loading"
    matTextSuffix
    [mode]="'indeterminate'"
    class="spinner"
    color="accent"
    [diameter]="14"
  ></mat-progress-spinner>

  <mat-label>{{ placeholder }}</mat-label>
  <mat-select
    (selectionChange)="onChange($event)"
    [formControl]="phone_code"
    [required]="required"
    (openedChange)="onSelectOpen()"
    data-cy="country_select"
  >
    <mat-form-field class="filter">
      <input
        #filter_control_el
        [formControl]="filter_control"
        type="text"
        matInput
      />
    </mat-form-field>
    <mat-select-trigger>
      {{ phone_code.value ? '+' + phone_code.value : '' }}
    </mat-select-trigger>
    <mat-option [value]="undefined">None</mat-option>
    <mat-option
      *ngFor="let country of filteredCountries"
      [value]="country.phone_code"
      data-cy="country_options"
    >
      <span class="phone-code">(+{{ country.phone_code || '000' }})</span>
      {{ country.name }}
    </mat-option>
  </mat-select>
</mat-form-field>
