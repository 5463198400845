import {
  ChangeDetectorRef,
  Component,
  ContentChildren,
  Directive,
  ElementRef,
  EmbeddedViewRef,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  Renderer2,
  TemplateRef,
  ViewChild,
  ViewChildren,
  ViewContainerRef,
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import {
  MatBottomSheet,
  MatBottomSheetRef,
} from '@angular/material/bottom-sheet';
import { dsConfig } from '@design-system/cdk/config';
import { DateTime } from '@paldesk/shared-lib/utils/date-utils';
import { filterTruthy } from '@shared-lib/rxjs';
import {
  BehaviorSubject,
  Observable,
  Subject,
  debounceTime,
  distinctUntilChanged,
  startWith,
  takeUntil,
} from 'rxjs';
import { DsFilterDrawerComponent } from './filter-drawer.component';

@Directive({
  selector: '[dsFilterItem]',
  standalone: false,
})
export class DsFilterItemDirective implements OnInit, OnDestroy {
  @Input() set dsFilterItem(val: AbstractControl) {
    if (val) {
      this.link(val);
    }
  }
  @Input() dsFilterItemSortKey = 0;
  view: EmbeddedViewRef<any>;

  protected links: Set<AbstractControl> = new Set();
  destroy$ = new Subject<void>();
  private hasValue_ = new BehaviorSubject<boolean>(false);
  hasValue$ = this.hasValue_.asObservable();

  get hasValue(): boolean {
    return this.hasValue_.getValue();
  }

  get value(): any {
    const [control] = Array.from(this.links);
    return control?.value;
  }

  constructor(
    public templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private renderer: Renderer2,
  ) {}

  ngOnInit(): void {
    this.view = this.viewContainer.createEmbeddedView(this.templateRef);
    this.renderer.removeClass(this.view.rootNodes[0], 'full-width');
    this.renderer.addClass(this.view.rootNodes[0], 'ds-filter-item');
  }

  link(control: AbstractControl): void {
    control.valueChanges
      .pipe(startWith(control.value), takeUntil(this.destroy$))
      .subscribe({
        next: (v) => {
          const hasVal = this.checkIfValueExists(v);
          this.hasValue_.next(hasVal);

          this.links.forEach((link) => {
            link.patchValue(v, {
              emitEvent: false,
              emitModelToViewChange: !(
                control.errors && control.errors['matDatepickerParse']?.text
              ),
            });
          });
        },
      });
    this.links.add(control);
  }

  private checkIfValueExists(v: any): boolean {
    if (v === null || v === undefined) return false;
    if (v instanceof Date || DateTime.isNativeDate(v)) return true;
    if (typeof v === 'number') return true; // This includes 0
    if (typeof v === 'string') return v.trim().length > 0;
    if (Array.isArray(v)) return v.length > 0;
    if (typeof v === 'object') return Object.keys(v).length > 0;
    return !!v;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }
}

@Component({
  selector: 'ds-filter-wrapper',
  template: '',
  standalone: false,
})
export class FilterWrapperComponent {
  constructor(public dsFilterComponent: DsFilterComponent) {}

  @ViewChildren(DsFilterItemDirective, { read: DsFilterItemDirective })
  set items(val: QueryList<DsFilterItemDirective>) {
    this.dsFilterComponent?.addItems(val);
  }
}

@Component({
  selector: 'ds-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
  standalone: false,
})
export class DsFilterComponent implements OnDestroy {
  listItems: TemplateRef<any>[];
  drawerItems: DsFilterItemDirective[] = [];
  @Input() showApplyButton: boolean;
  @Output() resetFilter = new EventEmitter<void>();
  @Output() apply = new EventEmitter<void>();
  badge = 0;

  @ContentChildren(DsFilterItemDirective, { read: DsFilterItemDirective })
  set items_(val: QueryList<DsFilterItemDirective>) {
    this.addItems(val);
  }
  @ViewChild('resetBtn') resetBtn: ElementRef;
  @ViewChild('applyBtn') applyBtn: ElementRef;
  @ViewChild('applyBtnHelper') applyBtnHelper: any;
  @ViewChild('mainHolder') set mainHolder(value: ElementRef) {
    if (!this.resizeObserver && value) {
      new Observable((subscriber) => {
        this.resizeObserver = new ResizeObserver((entries) => {
          subscriber.next(entries[0].contentRect.width);
        });

        this.resizeObserver.observe(value.nativeElement, {
          box: 'border-box',
        });

        return () => {
          this.resizeObserver.unobserve(value.nativeElement);
          this.resizeObserver.disconnect();
        };
      })
        .pipe(
          debounceTime(50),
          distinctUntilChanged(),
          filterTruthy(),
          takeUntil(this.destroy$),
        )
        .subscribe((width) => {
          this.zone.run(() => {
            this.containerWidth = width as number;
            this.setItemsViewMode();
          });
        });
    }
  }

  resizeObserver: ResizeObserver;
  containerWidth: number;
  showAllFilter: boolean;
  bottomSheetRef?: MatBottomSheetRef<DsFilterDrawerComponent>;

  private readonly destroy$ = new Subject<void>();

  constructor(
    private cd: ChangeDetectorRef,
    private zone: NgZone,
    private bottomSheet: MatBottomSheet,
  ) {}

  private calculateBadgeCount(): number {
    return this.drawerItems.filter((item) => item.hasValue).length;
  }

  updateBadgeCount(): void {
    this.badge = this.calculateBadgeCount();
    this.cd.detectChanges();
  }

  addItems(items: QueryList<DsFilterItemDirective>) {
    const newItems = items.filter((item) => !this.drawerItems.includes(item));
    this.drawerItems = [...this.drawerItems, ...newItems].sort(
      (a, b) => a.dsFilterItemSortKey - b.dsFilterItemSortKey,
    );

    if (this.bottomSheetRef) {
      this.bottomSheetRef.instance.data.drawerItems = this.drawerItems.map(
        (x) => x.templateRef,
      );
    }

    newItems.forEach((x) => {
      x.hasValue$
        .pipe(
          distinctUntilChanged(),
          takeUntil(this.destroy$),
          takeUntil(x.destroy$),
        )
        .subscribe({
          next: () => this.updateBadgeCount(),
        });
      x.destroy$.subscribe({ next: () => this.removeItem(x) });
    });

    this.updateBadgeCount();
    this.setItemsViewMode();
  }

  removeItem(item: DsFilterItemDirective) {
    this.drawerItems = this.drawerItems.filter((itm) => itm !== item);
    this.updateBadgeCount();
    this.setItemsViewMode();
  }

  setItemsViewMode() {
    let totalContentsWidth = 0;
    this.listItems = [];
    this.showAllFilter = this.drawerItems.length > 1;

    this.drawerItems.forEach((item) => {
      let totalWidthWithItem: number =
        totalContentsWidth +
        item.view.rootNodes[0].offsetWidth +
        dsConfig.spacing / 2;

      if (!this.resetBtn) {
        totalWidthWithItem += 50;
      }
      if (this.showApplyButton && !this.applyBtn) {
        totalWidthWithItem +=
          this.applyBtnHelper?._elementRef.nativeElement.offsetWidth ||
          0 + dsConfig.spacing / 2;
      }

      if (this.containerWidth > totalWidthWithItem) {
        this.listItems.push(item.templateRef);
        totalContentsWidth = totalWidthWithItem;
      } else {
        return;
      }
    });
    this.cd.detectChanges();
  }

  openAllFilters(): void {
    this.bottomSheetRef = this.bottomSheet.open(DsFilterDrawerComponent, {
      panelClass: 'filter-panel',
      data: {
        drawerItems: this.drawerItems.map((x) => x.templateRef),
        showApplyButton: this.showApplyButton,
      },
    });
    this.bottomSheetRef.instance.apply
      .pipe(takeUntil(this.destroy$))
      .subscribe({ next: () => this.apply.emit() });
    this.bottomSheetRef.instance.resetFilter
      .pipe(takeUntil(this.destroy$))
      .subscribe({ next: () => this.resetFilter.emit() });
    this.bottomSheetRef
      .afterDismissed()
      .subscribe(() => (this.bottomSheetRef = undefined));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }
}
