import { Component, Input } from '@angular/core';

@Component({
  selector: 'ds-table-loading',
  templateUrl: './table-loading.component.html',
  styleUrls: ['./table-loading.component.scss'],
  standalone: false,
})
export class DsTableLoadingComponent {
  @Input() numberOfHeaders = 4;
  @Input() numberOfRows = 10;

  counter(i: number) {
    return new Array(i);
  }
}
