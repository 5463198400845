import {
  Component,
  EventEmitter,
  Inject,
  Output,
  TemplateRef,
} from '@angular/core';
import {
  MatBottomSheetRef,
  MAT_BOTTOM_SHEET_DATA,
} from '@angular/material/bottom-sheet';

@Component({
  selector: 'ds-filter-drawer',
  styleUrls: ['./filter-drawer.component.scss'],
  templateUrl: './filter-drawer.component.html',
  standalone: false,
})
export class DsFilterDrawerComponent {
  @Output() resetFilter = new EventEmitter<void>();
  @Output() apply = new EventEmitter<void>();
  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA)
    public data: { drawerItems: TemplateRef<any>[]; showApplyButton: boolean },
    public bottomSheetRef: MatBottomSheetRef,
  ) {}
}
