import { Inject, Injectable, Optional } from '@angular/core';
import { UserContext, UserService } from '@features/auth';
import { TranslateService } from '@ngx-translate/core';
import { ReplaySubject, take } from 'rxjs';
import {
  FreshdeskWebwidgetConfig,
  FreshdeskWebwidgetConfigToken,
} from './freshdesk-widget-config';
@Injectable({
  providedIn: 'root',
})
export class FreshdeskWidgetService {
  labels = {} as any;
  init = new ReplaySubject<boolean>();
  helpWidget = false;
  lang = 'en';
  user: UserContext | undefined;

  constructor(
    private translateService: TranslateService,
    @Optional()
    @Inject(FreshdeskWebwidgetConfigToken)
    private config: FreshdeskWebwidgetConfig,
    @Optional() private userService: UserService,
  ) {
    if (config) {
      if (userService) {
        this.userService.currentUser.pipe(take(1)).subscribe((user) => {
          this.user = user;
        });
      }
      this.initWidget();
    }
  }

  initWidget() {
    if (this.user) {
      this.lang = this.user.lang ? this.user.lang.toLowerCase() : 'en';
    }

    const window = this.getWindow();

    window.fwSettings = {
      widget_id: this.config.widgetId,
      locale: this.lang,
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    window.FreshworksWidget ||
      (function () {
        if ('function' != typeof window.FreshworksWidget) {
          const n: any = function () {
            // eslint-disable-next-line prefer-rest-params
            n['q'].push(arguments);
          };
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          (n['q'] = []), (window.FreshworksWidget = n);
        }
      })();

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = `https://euc-widget.freshworks.com/widgets/${this.config.widgetId}.js`;
    document.body.append(script);

    this.FreshworksWidget('hide', 'launcher');

    this.init.next(true);
  }

  showTicketWidgetIconLauncher() {
    this.FreshworksWidget('show', 'launcher');
  }

  /* Hides freshdesk widget for ticket creation on page */
  hideTicketWidgetIconLauncher() {
    if (this.FreshworksWidget) {
      this.FreshworksWidget('hide', 'launcher');
    }
  }

  openTheWidget(
    controlTranslations?: WidgetTranslations[],
    subject?: string,
    context?: string,
  ) {
    this.destroyTheWidget();
    this.FreshworksWidget('boot');
    this.translateLabels(this.lang, controlTranslations);
    this.FreshworksWidget('prefill', 'ticketForm', {
      name: `${this.user?.firstname} ${this.user?.lastname}`,
      email: this.user?.email ?? '',
      subject: subject || '',
      description: '',
      custom_fields: {
        cf_context: context || '',
      },
    });
    this.FreshworksWidget('hide', 'ticketForm', ['custom_fields.cf_context']);
    this.FreshworksWidget('open');
    this.FreshworksWidget('hide', 'launcher');
  }

  hideTheWidget() {
    this.FreshworksWidget('close');
  }

  destroyTheWidget() {
    this.FreshworksWidget('destroy');
  }

  translateLabels(lang: string, controlTranslations?: WidgetTranslations[]) {
    if (controlTranslations) {
      this.FreshworksWidget('setLabels', controlTranslations);
    } else {
      this.translateService
        .get([
          'freshdesk_widget.banner_support',
          'freshdesk_widget.title_support',
          'freshdesk_widget.submit',
          'freshdesk_widget.confirmation',
          'freshdesk_widget.launcher',
        ])
        .subscribe((data) => {
          this.labels[lang] = {
            banner: data['freshdesk_widget.banner_support'],
            launcher: data['freshdesk_widget.launcher'],
            contact_form: {
              title: data['freshdesk_widget.title_support'],
              submit: data['freshdesk_widget.submit'],
              confirmation: data['freshdesk_widget.confirmation'],
            },
          };
          this.FreshworksWidget('setLabels', this.labels);
        });
    }
  }

  getWindow(): any {
    return window;
  }

  get FreshworksWidget(): any {
    const window = this.getWindow();
    return window.FreshworksWidget;
  }
}

export interface WidgetTranslations {
  banner: string;
  launcher: string;
  contact_form: {
    title: string;
    submit: string;
    confirmation: string;
  };
}
