import { CdkColumnDef } from '@angular/cdk/table';
import { Component, Input } from '@angular/core';
import { MatSort, MatSortable } from '@angular/material/sort';

@Component({
  selector: 'ds-alternative-table-renderer-item',
  templateUrl: './alternative-table-renderer-item.component.html',
  styleUrls: ['./alternative-table-renderer-item.component.scss'],
  standalone: false,
})
export class AlternativeTableRendererItemComponent {
  @Input() data: any;
  @Input() set sort(value: MatSort) {
    // reset sortables on each item rendering
    // because it can not have more than one sort-header with the same name
    // we render the header multiple times (once per item)
    if (value) {
      value.sortables = new Map<string, MatSortable>();
    }
  }

  @Input() headerColumns: CdkColumnDef[];
  @Input() bodyColumns: CdkColumnDef[];
  @Input() footerColumns: CdkColumnDef[];
  @Input() isExpansionPanelDisabled: boolean;
}
