import { Component, Input, ViewEncapsulation } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  SuggestionHistoryItemResponse,
  SuggestionItemResponse,
} from '@paldesk/shared-lib/data-access/search-generated';

@Component({
  selector: 'ds-new-paldesk-suggestion',
  imports: [MatTooltipModule],
  templateUrl: './suggestion.component.html',
  styleUrl: './suggestion.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class SuggestionComponent {
  @Input()
  suggestion: SuggestionHistoryItemResponse | SuggestionItemResponse;

  tooltipText = '';

  checkIfEllipsisActive(element: HTMLElement): void {
    const parentElement = element.parentElement;
    if (parentElement && this.suggestion.search_term) {
      if (parentElement.scrollWidth > parentElement.clientWidth) {
        this.tooltipText = this.suggestion.search_term;
      } else {
        this.tooltipText = '';
      }
    }
  }
}
