import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Optional,
  Output,
} from '@angular/core';
import { AbstractControl, FormGroupDirective } from '@angular/forms';
import { matExpansionAnimations } from '@angular/material/expansion';
import { Params } from '@angular/router';
import { dsAnimations } from '@design-system/cdk/animations';
import { TypedQueryParamsService } from '@shared-lib/typed-query-params';
import { isEqual } from 'lodash-es';
import { ReplaySubject, Subject, combineLatest } from 'rxjs';
import {
  debounceTime,
  delay,
  distinctUntilChanged,
  filter,
  map,
  skipWhile,
  takeUntil,
} from 'rxjs/operators';

@Component({
  selector: 'ds-deprecated-filter',
  templateUrl: './filter-deprecated.component.html',
  styleUrls: ['./filter-deprecated.component.scss'],
  animations: [
    matExpansionAnimations.indicatorRotate,
    dsAnimations.collapseFilter,
  ],
  providers: [TypedQueryParamsService],
  standalone: false,
})
export class DsDeprecatedFilterComponent implements AfterViewInit, OnDestroy {
  @Input() hideReset: boolean;
  @Input() disableExpand: boolean;

  @Input() IsInputSizeEqual: boolean;

  @Input() set enableUrlFilterOptions(value: boolean) {
    this._enableUrlFilterOptions$.next(value);
  }
  @Input()
  excludedFormControlNames: string[];

  @Input() defaultValues: {
    [key: string]: any;
  } = {};

  @Input()
  isExpanded = false;

  @Output() resetFilter = new EventEmitter();

  //Emits, when all values from url to form were patched
  //used on page load, where wait for patch is needed and then BE filtering can be executed
  @Output() controlsPatched = new EventEmitter();

  private _enableUrlFilterOptions$ = new ReplaySubject<boolean>();
  private _destroy$ = new Subject<void>();

  constructor(
    @Optional() private _parentFormGroup: FormGroupDirective,
    private _paramsService: TypedQueryParamsService,
  ) {
    combineLatest([
      this._enableUrlFilterOptions$,
      this._paramsService.valueChanged$,
    ])
      .pipe(
        filter(([enabled]) => enabled && !!this._parentFormGroup?.form),
        distinctUntilChanged((a, b) => isEqual(a, b)),
        skipWhile(([, params]) => {
          let isParamSet = false;
          for (const key in params) {
            if (params[key]) isParamSet = true;
          }
          return !isParamSet;
        }),
        delay(0),
        map(([, params]) => params),
        takeUntil(this._destroy$),
      )
      .subscribe((params) => {
        this._setOptionsFromParams(params, this._parentFormGroup.form.controls);
        this.controlsPatched.emit();
      });
    this._enableUrlFilterOptions$
      .pipe(
        filter((enabled) => !enabled),
        delay(0),
        takeUntil(this._destroy$),
      )
      .subscribe(() => this._paramsService.resetAll());
  }

  ngAfterViewInit() {
    if (this._parentFormGroup?.form) {
      for (const key in this._parentFormGroup.form.controls) {
        if (
          this._parentFormGroup.form.controls[key] &&
          (!this.excludedFormControlNames ||
            !this.excludedFormControlNames.includes(key))
        ) {
          let formValueChanges$ =
            this._parentFormGroup.form.controls[key].valueChanges;
          if (
            typeof this._parentFormGroup.form.controls[key].value === 'string'
          ) {
            formValueChanges$ = formValueChanges$.pipe(debounceTime(500));
          } else if (
            typeof this._parentFormGroup.form.controls[key].value === 'object'
          ) {
            formValueChanges$ = formValueChanges$.pipe(debounceTime(50));
          }

          combineLatest([formValueChanges$, this._enableUrlFilterOptions$])
            .pipe(
              filter(([, enabled]) => enabled),
              distinctUntilChanged(
                (a, b) => isEqual(a, b) && !this.defaultValues[key],
              ),
              map(([value]) => value),
              takeUntil(this._destroy$),
            )
            .subscribe((value) => {
              this._setParamsFromControls(
                { [key]: value },
                this._paramsService,
              );
            });
        }
      }
    }
  }

  ngOnDestroy() {
    this._destroy$.next();
  }

  resetInternal() {
    this.resetFilter.emit();
    if (this._parentFormGroup && this.resetFilter.observers.length === 0)
      this._parentFormGroup.form.reset();
  }

  setExpanded(expanded: boolean) {
    this.isExpanded = expanded;
  }

  private _setOptionsFromParams(
    params: Params,
    controls: {
      [key: string]: AbstractControl;
    },
  ) {
    for (const key in params) {
      if (controls[key]) {
        if (params[key] === undefined) {
          if (this.defaultValues[key]) {
            controls[key].patchValue(this.defaultValues[key]);
          } else {
            controls[key].reset();
          }
        } else if (!isEqual(params[key], controls[key].value)) {
          controls[key].patchValue(params[key]);
        }
      }
    }
  }

  private _setParamsFromControls(
    controls: { [key: string]: any },
    paramsService: TypedQueryParamsService,
  ) {
    for (const control in controls) {
      if (
        (typeof controls[control] == 'boolean' || !!controls[control]) &&
        (!Array.isArray(controls[control]) || controls[control].length > 0)
      ) {
        paramsService.set(control, controls[control]);
      } else {
        if (this.defaultValues[control]) {
          paramsService.set(control, this.defaultValues[control]);
        } else {
          paramsService.reset(control);
        }
      }
    }
  }
}
