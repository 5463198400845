@if (store.bannerVisible()) {
  <mat-card [class]="type" class="banner print-hidden">
    <mat-card-content
      fxLayout="row"
      fxLayoutAlign="space-between center"
      fxLayout.lt-sm="column"
      fxLayoutAlign.lt-sm="center"
    >
      <div
        class="full-width"
        fxLayout="row"
        fxLayoutAlign="start"
        fxLayoutAlign.lt-sm="center"
      >
        <ds-text-indicator [type]="type" [noColor]="true">
          <div>
            {{ 'hc_status_banner.issue_text' | translate }}
          </div>
        </ds-text-indicator>
      </div>

      <div
        fxLayout="row"
        fxLayoutAlign="center"
        [fxLayoutGap]="0.5 | dsSpacing"
      >
        <button class="banner-button-color" mat-button (click)="hideBanner()">
          {{ 'general.close' | translate }}
        </button>
        <a
          class="banner-button-color"
          target="_blank"
          mat-button
          [href]="statusPaldeskPageLink"
          >{{ 'hc_status_banner.details' | translate }}</a
        >
      </div>
    </mat-card-content>
  </mat-card>
}
