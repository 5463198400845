import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PdLetDirectiveModule } from '@cdk/pd-let';
import { StorePageSizeDirectiveModule } from '@cdk/store-page-size';
import { DsSpacingModule } from '@design-system/cdk/spacing';
import { DsFilterInputModule } from '@design-system/components/filter-input';
import { DsLoadingModule } from '@design-system/components/loading';
import { DsPlaceholderModule } from '@design-system/components/placeholder';
import { DsTableModule } from '@design-system/components/table';
import { DsTextIndicatorModule } from '@design-system/components/text-indicator';
import { DsSnackbarComponent } from '@design-system/feature/snackbar';
import { TranslateModule } from '@ngx-translate/core';
import { LanguageSelectionModule } from '@shared-lib/language-selection';
import {
  ConfirmEmailModule,
  UiKitPhoneCodeSelectionModule,
} from '@ui-kit/form-control';
import { CompanyNamePipe } from './company-name.pipe';
import { DisplayColumnsSettingsService } from './display-columns-settings.service';
import { SanitizeHtmlPipe } from './sanitize-html.pipe';

const sharedModules = [
  CommonModule,
  FlexLayoutModule,
  FormsModule,
  ReactiveFormsModule,
  DsSpacingModule,
  UiKitPhoneCodeSelectionModule,
  ConfirmEmailModule,
  StorePageSizeDirectiveModule,
  MatIconModule,
  MatInputModule,
  MatCardModule,
  MatProgressSpinnerModule,
  MatButtonModule,
  MatFormFieldModule,
  MatProgressBarModule,
  MatTableModule,
  MatTooltipModule,
  MatMenuModule,
  MatPaginatorModule,
  MatDialogModule,
  MatSortModule,
  MatSelectModule,
  MatCheckboxModule,
  MatTooltipModule,
  MatSlideToggleModule,
  TranslateModule,
  DsTableModule,
  DsPlaceholderModule,
  DsSnackbarComponent,
  LanguageSelectionModule,
  PdLetDirectiveModule,
  DsFilterInputModule,
  DsTextIndicatorModule,
  GoogleMapsModule,
  MatAutocompleteModule,
];

@NgModule({
  imports: [sharedModules, DsLoadingModule],
  exports: [sharedModules, CompanyNamePipe, SanitizeHtmlPipe],
  providers: [DisplayColumnsSettingsService],
  declarations: [CompanyNamePipe, SanitizeHtmlPipe],
})
export class SharedModule {}
