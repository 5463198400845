/**
 * v3 - Business Partner Management
 * Business Partner Management API
 *
 * The version of the OpenAPI document: 3.0
 * Contact: m.kollar@palfinger.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ParentInvalidProductLineResponse } from './parentInvalidProductLineResponse';
import { ParentProductLineResponse } from './parentProductLineResponse';


export interface ParentResponse { 
    
    id: number;
    
    sap_id?: string;
    
    business_partner_number?: string;
    
    debitor_id: number;
    
    name?: string;
    
    name2?: string;
    
    partner_type: ParentResponsePartnerType;
    
    product_lines: Array<ParentProductLineResponse>;
    
    invalid_product_lines: Array<ParentInvalidProductLineResponse>;
}
export enum ParentResponsePartnerType {
    AG = 'AG',
    AREA = 'AREA',
    Dealer = 'Dealer',
    SubDealer = 'SubDealer',
    EndCustomer = 'EndCustomer',
    Supplier = 'Supplier'
};



