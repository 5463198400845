export const APPS = {
  BUSINESS_PARTNER_MANAGEMENT: {
    ID: 'APP200',
    ROLES: {
      ADMIN: 'APP200:BPM_admin',
      END_CUSTOMER_COMPANY_MANAGEMENT: 'APP200:BPM_endcustomer_company_mgmt',
      NETWORK_COMPANY_MANAGEMENT: 'APP200:BPM_network_company_mgmt',
      NETWORK_USER_MANAGEMENT: 'APP200:BPM_network_user_mgmt',
      SUPPLIER_MANAGEMENT: 'APP200:BPM_supplier_mgmt',
      ADD_SUBORDINATED_COMPANY: 'APP200:BPM_add_subordinated_company',
      AREA_MANAGER: 'APP200:BPM_area_manager',
      PALSOFT_USER: 'APP200:BPM_palsoft_licensing_user',
      PARTNER_NETWORK_READ_ONLY: 'APP200:BPM_partner_network_read_only',
      USER_PERMISSION: 'APP200:BPM_user_permission',
    },
  },
  USER_MANAGEMENT: {
    ID: 'APP300',
    ROLES: {
      USER: 'APP300:um_user',
      ADMIN: 'APP300:um_admin',
    },
  },
};
