import { Component } from '@angular/core';
import { DsSpotlight } from '@design-system/feature/spotlight';
import { Router } from '@angular/router';

@Component({
  selector: 'bpm-spotlight-reset',
  templateUrl: './spotlight-reset.component.html',
  styleUrls: ['./spotlight-reset.component.scss'],
  standalone: false,
})
export class SpotlightResetComponent {
  constructor(
    private _dsSpotlight: DsSpotlight,
    private router: Router,
  ) {}

  resetSpotlight() {
    this._dsSpotlight.resetSpotlight('filter-spotlight-above');
    this._dsSpotlight.resetSpotlight('spotlight-welcome-message');
    this._dsSpotlight.resetSpotlight('invite-spotlight');
    this._dsSpotlight.resetSpotlight('settings-spotlight');
    this._dsSpotlight.resetSpotlight('user-settings-spotlight');
    this._dsSpotlight.resetSpotlight('user-spotlight');
    this._dsSpotlight.resetSpotlight('bpm-spotlight-helper');
    this.router.navigate(['/palfinger-partner']);
  }
}
