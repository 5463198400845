/**
 * v3 - Business Partner Management
 * Business Partner Management API
 *
 * The version of the OpenAPI document: 3.0
 * Contact: m.kollar@palfinger.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { CompanyStatus } from '../model/companyStatus';
// @ts-ignore
import { CopyCustomerAdminRequest } from '../model/copyCustomerAdminRequest';
// @ts-ignore
import { CopyCustomerAdminResponse } from '../model/copyCustomerAdminResponse';
// @ts-ignore
import { GetCompaniesResponse } from '../model/getCompaniesResponse';
// @ts-ignore
import { ProblemDetails } from '../model/problemDetails';
// @ts-ignore
import { SetCompanyRolesRequest } from '../model/setCompanyRolesRequest';
// @ts-ignore
import { SetCompanyRolesResponse } from '../model/setCompanyRolesResponse';
// @ts-ignore
import { SyncEndCustomerCompanyFromSapRequest } from '../model/syncEndCustomerCompanyFromSapRequest';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class CompanyManagerService {

    protected basePath = 'provide_injection_token_for_CompanyManagerService';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Create a copy of customer admin user
     * @param apiVersion The requested API version
     * @param copyCustomerAdminRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public bpmCopyCustomerAdmin(apiVersion: '3.0', copyCustomerAdminRequest?: CopyCustomerAdminRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'application/problem+json',}): Observable<CopyCustomerAdminResponse>;
    public bpmCopyCustomerAdmin(apiVersion: '3.0', copyCustomerAdminRequest?: CopyCustomerAdminRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'application/problem+json',}): Observable<HttpResponse<CopyCustomerAdminResponse>>;
    public bpmCopyCustomerAdmin(apiVersion: '3.0', copyCustomerAdminRequest?: CopyCustomerAdminRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'application/problem+json',}): Observable<HttpEvent<CopyCustomerAdminResponse>>;
    public bpmCopyCustomerAdmin(apiVersion: '3.0', copyCustomerAdminRequest?: CopyCustomerAdminRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'application/problem+json',}): Observable<any> {
        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling bpmCopyCustomerAdmin.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (apiVersion !== undefined && apiVersion !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>apiVersion, 'api-version');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (OAuth2SecurityScheme) required
        localVarCredential = this.configuration.lookupCredential('OAuth2SecurityScheme');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json',
                'application/problem+json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.post<CopyCustomerAdminResponse>(`${this.configuration.basePath}/CompanyManager/CopyCustomerAdmin`,
            copyCustomerAdminRequest,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get list of all end-customer Companies
     * @param apiVersion The requested API version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public bpmGetEndCustomerCompanies(apiVersion: '3.0', observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'application/problem+json',}): Observable<GetCompaniesResponse>;
    public bpmGetEndCustomerCompanies(apiVersion: '3.0', observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'application/problem+json',}): Observable<HttpResponse<GetCompaniesResponse>>;
    public bpmGetEndCustomerCompanies(apiVersion: '3.0', observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'application/problem+json',}): Observable<HttpEvent<GetCompaniesResponse>>;
    public bpmGetEndCustomerCompanies(apiVersion: '3.0', observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'application/problem+json',}): Observable<any> {
        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling bpmGetEndCustomerCompanies.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (apiVersion !== undefined && apiVersion !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>apiVersion, 'api-version');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (OAuth2SecurityScheme) required
        localVarCredential = this.configuration.lookupCredential('OAuth2SecurityScheme');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json',
                'application/problem+json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.get<GetCompaniesResponse>(`${this.configuration.basePath}/CompanyManager/GetCompanies`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Lock end-customer Company
     * @param companyId BPM ID of the end-customer Company to lock
     * @param apiVersion The requested API version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public bpmLockEndCustomerCompany(companyId: number, apiVersion: '3.0', observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json',}): Observable<any>;
    public bpmLockEndCustomerCompany(companyId: number, apiVersion: '3.0', observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json',}): Observable<HttpResponse<any>>;
    public bpmLockEndCustomerCompany(companyId: number, apiVersion: '3.0', observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json',}): Observable<HttpEvent<any>>;
    public bpmLockEndCustomerCompany(companyId: number, apiVersion: '3.0', observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/problem+json',}): Observable<any> {
        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling bpmLockEndCustomerCompany.');
        }
        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling bpmLockEndCustomerCompany.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (apiVersion !== undefined && apiVersion !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>apiVersion, 'api-version');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (OAuth2SecurityScheme) required
        localVarCredential = this.configuration.lookupCredential('OAuth2SecurityScheme');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/problem+json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/CompanyManager/Lock/${encodeURIComponent(String(companyId))}`,
            null,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Send reminder email to the end-customer Company administrator to finish the registration
     * @param companyId BPM ID of the end-customer Company
     * @param apiVersion The requested API version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public bpmSendEndCustomerCompanyActivationReminder(companyId: number, apiVersion: '3.0', observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json',}): Observable<any>;
    public bpmSendEndCustomerCompanyActivationReminder(companyId: number, apiVersion: '3.0', observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json',}): Observable<HttpResponse<any>>;
    public bpmSendEndCustomerCompanyActivationReminder(companyId: number, apiVersion: '3.0', observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json',}): Observable<HttpEvent<any>>;
    public bpmSendEndCustomerCompanyActivationReminder(companyId: number, apiVersion: '3.0', observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/problem+json',}): Observable<any> {
        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling bpmSendEndCustomerCompanyActivationReminder.');
        }
        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling bpmSendEndCustomerCompanyActivationReminder.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (apiVersion !== undefined && apiVersion !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>apiVersion, 'api-version');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (OAuth2SecurityScheme) required
        localVarCredential = this.configuration.lookupCredential('OAuth2SecurityScheme');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/problem+json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/CompanyManager/SendActivationReminder/${encodeURIComponent(String(companyId))}`,
            null,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Set Company roles
     * @param apiVersion The requested API version
     * @param setCompanyRolesRequest Company roles data to be modified
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public bpmSetEndCustomerCompanyRoles(apiVersion: '3.0', setCompanyRolesRequest?: SetCompanyRolesRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'application/problem+json',}): Observable<SetCompanyRolesResponse>;
    public bpmSetEndCustomerCompanyRoles(apiVersion: '3.0', setCompanyRolesRequest?: SetCompanyRolesRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'application/problem+json',}): Observable<HttpResponse<SetCompanyRolesResponse>>;
    public bpmSetEndCustomerCompanyRoles(apiVersion: '3.0', setCompanyRolesRequest?: SetCompanyRolesRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'application/problem+json',}): Observable<HttpEvent<SetCompanyRolesResponse>>;
    public bpmSetEndCustomerCompanyRoles(apiVersion: '3.0', setCompanyRolesRequest?: SetCompanyRolesRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'application/problem+json',}): Observable<any> {
        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling bpmSetEndCustomerCompanyRoles.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (apiVersion !== undefined && apiVersion !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>apiVersion, 'api-version');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (OAuth2SecurityScheme) required
        localVarCredential = this.configuration.lookupCredential('OAuth2SecurityScheme');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json',
                'application/problem+json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.post<SetCompanyRolesResponse>(`${this.configuration.basePath}/CompanyManager/SetRoles`,
            setCompanyRolesRequest,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Switch existing end-customer Company administrator for the different person
     * @param companyId BPM ID of the end-customer Company
     * @param emailAddress Email address of the new end-customer Company administrator
     * @param apiVersion The requested API version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public bpmSwitchEndCustomerCompanyAdmin(companyId: number, emailAddress: string, apiVersion: '3.0', observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json',}): Observable<any>;
    public bpmSwitchEndCustomerCompanyAdmin(companyId: number, emailAddress: string, apiVersion: '3.0', observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json',}): Observable<HttpResponse<any>>;
    public bpmSwitchEndCustomerCompanyAdmin(companyId: number, emailAddress: string, apiVersion: '3.0', observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json',}): Observable<HttpEvent<any>>;
    public bpmSwitchEndCustomerCompanyAdmin(companyId: number, emailAddress: string, apiVersion: '3.0', observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/problem+json',}): Observable<any> {
        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling bpmSwitchEndCustomerCompanyAdmin.');
        }
        if (emailAddress === null || emailAddress === undefined) {
            throw new Error('Required parameter emailAddress was null or undefined when calling bpmSwitchEndCustomerCompanyAdmin.');
        }
        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling bpmSwitchEndCustomerCompanyAdmin.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (apiVersion !== undefined && apiVersion !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>apiVersion, 'api-version');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (OAuth2SecurityScheme) required
        localVarCredential = this.configuration.lookupCredential('OAuth2SecurityScheme');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/problem+json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/CompanyManager/SwitchCompanyAdmin/${encodeURIComponent(String(companyId))}/${encodeURIComponent(String(emailAddress))}`,
            null,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Synchronize existing Company data from SAP
     * @param apiVersion The requested API version
     * @param syncEndCustomerCompanyFromSapRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public bpmSyncEndCustomerCompanyFromSap(apiVersion: '3.0', syncEndCustomerCompanyFromSapRequest?: SyncEndCustomerCompanyFromSapRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json',}): Observable<any>;
    public bpmSyncEndCustomerCompanyFromSap(apiVersion: '3.0', syncEndCustomerCompanyFromSapRequest?: SyncEndCustomerCompanyFromSapRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json',}): Observable<HttpResponse<any>>;
    public bpmSyncEndCustomerCompanyFromSap(apiVersion: '3.0', syncEndCustomerCompanyFromSapRequest?: SyncEndCustomerCompanyFromSapRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json',}): Observable<HttpEvent<any>>;
    public bpmSyncEndCustomerCompanyFromSap(apiVersion: '3.0', syncEndCustomerCompanyFromSapRequest?: SyncEndCustomerCompanyFromSapRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/problem+json',}): Observable<any> {
        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling bpmSyncEndCustomerCompanyFromSap.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (apiVersion !== undefined && apiVersion !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>apiVersion, 'api-version');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (OAuth2SecurityScheme) required
        localVarCredential = this.configuration.lookupCredential('OAuth2SecurityScheme');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/problem+json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/CompanyManager/Sap`,
            syncEndCustomerCompanyFromSapRequest,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Unlock end-customer Company
     * @param companyId BPM ID of the end-customer Company to unlock
     * @param apiVersion The requested API version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public bpmUnlockEndCustomerCompany(companyId: number, apiVersion: '3.0', observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'application/problem+json',}): Observable<CompanyStatus>;
    public bpmUnlockEndCustomerCompany(companyId: number, apiVersion: '3.0', observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'application/problem+json',}): Observable<HttpResponse<CompanyStatus>>;
    public bpmUnlockEndCustomerCompany(companyId: number, apiVersion: '3.0', observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'application/problem+json',}): Observable<HttpEvent<CompanyStatus>>;
    public bpmUnlockEndCustomerCompany(companyId: number, apiVersion: '3.0', observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json' | 'application/problem+json',}): Observable<any> {
        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling bpmUnlockEndCustomerCompany.');
        }
        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling bpmUnlockEndCustomerCompany.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (apiVersion !== undefined && apiVersion !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>apiVersion, 'api-version');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (OAuth2SecurityScheme) required
        localVarCredential = this.configuration.lookupCredential('OAuth2SecurityScheme');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json',
                'application/problem+json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.post<CompanyStatus>(`${this.configuration.basePath}/CompanyManager/Unlock/${encodeURIComponent(String(companyId))}`,
            null,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
