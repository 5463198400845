import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'companyName',
  standalone: false,
})
export class CompanyNamePipe implements PipeTransform {
  transform(name1: string, name2?: string, separator = ' '): string {
    if (name2 && name2.trim().length > 0) {
      return `${name1.trim()}${separator}${name2.trim()}`;
    }
    return name1.trim();
  }
}
