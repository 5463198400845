import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FlexLayoutModule, MediaObserver } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { DomSanitizer } from '@angular/platform-browser';
import { DsSpacingModule } from '@design-system/cdk/spacing';
import { TranslateModule } from '@ngx-translate/core';
import { PageActionsStore } from './page-actions.store';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router, ActivationEnd } from '@angular/router';
import {
  filter,
  distinctUntilChanged,
  map,
  throttleTime,
  Observable,
  of,
} from 'rxjs';
import { CommonModule } from '@angular/common';
import { Store } from '@ngrx/store';
import { DsLoadingModule } from '@design-system/components/loading';
import { PdLetDirectiveModule } from '@cdk/pd-let';
import { GoogleAnalyticsClickListenerDirective } from '@shared-lib/google-analytics';

@Component({
  selector: 'ds-page-actions-v2',
  imports: [
    DsSpacingModule,
    TranslateModule,
    FlexLayoutModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatMenuModule,
    CommonModule,
    DsLoadingModule,
    PdLetDirectiveModule,
    GoogleAnalyticsClickListenerDirective,
  ],
  templateUrl: './page-actions.component.html',
  styleUrl: './page-actions.component.scss',
  changeDetection: ChangeDetectionStrategy.Default,
})
export class PageActionsComponent {
  title$?: Observable<string | null>;

  constructor(
    public store: PageActionsStore,
    public sanitizer: DomSanitizer,
    public media: MediaObserver,
    private _router: Router,
    private _store: Store,
  ) {
    this._router.events
      .pipe(
        filter((event) => event instanceof ActivationEnd),
        throttleTime(100),
        map(
          (event) =>
            (<ActivationEnd>event).snapshot.data?.breadcrumb ||
            (<ActivationEnd>event).snapshot.data?.title,
        ),
        distinctUntilChanged(),
        takeUntilDestroyed(),
      )
      .subscribe(
        (title) =>
          (this.title$ =
            title === undefined
              ? undefined
              : typeof title === 'string'
                ? of(title)
                : this._store.select(title)),
      );
  }
}
