import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { filterTruthy } from '@shared-lib/rxjs';
import { Observable } from 'rxjs';
import {
  HELP_PAGES_CONFIG,
  HelpPagesConfig,
  HelpPagesService,
} from '../shared/';

@Component({
  selector: 'ds-help-page',
  templateUrl: './help-page.component.html',
  styleUrls: ['./help-page.component.scss'],
  standalone: false,
})
export class HelpPageComponent implements OnInit, OnDestroy {
  convertedData$: Observable<string>;
  isLoading$: Observable<boolean>;

  constructor(
    @Optional()
    @Inject(HELP_PAGES_CONFIG)
    private help_pages_config: HelpPagesConfig,
    private _helpPagesService: HelpPagesService,
  ) {
    this.convertedData$ = this._helpPagesService
      .getContent$()
      .pipe(filterTruthy());

    this.isLoading$ = this._helpPagesService.getIsContentLoading$();
  }

  ngOnInit() {
    this._helpPagesService.initializeMenu(this.help_pages_config);
  }

  ngOnDestroy() {
    this._helpPagesService.setIsHelpActive(false);
  }
}
